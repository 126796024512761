import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from './i18n';
import Vuex from "vuex";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import GlobalFunctions from "./mixins/GlobalFunctions.vue";
import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

const isProd = process.env.NODE_ENV === "production";

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(Snotify);

Vue.use(VueAnalytics, {
  id: "UA-10262877-3",
  router,
  autoTracking: {
    skipSamePath: true
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
});

Vue.prototype.$apiUrl = window.location.origin;
Vue.prototype.$apiUrl = "https://previewbox.verlauf.at";

Vue.prototype.$apiUrlAssets = Vue.prototype.$apiUrl + "/assets";
Vue.prototype.$apiUrlJob = Vue.prototype.$apiUrl + "/api/api/job";
Vue.prototype.$apiUrlUpload = Vue.prototype.$apiUrl + "/api/api/upload";
Vue.prototype.$apiUrlStatus = Vue.prototype.$apiUrl + "/api/api/status";
Vue.prototype.$apiUrlDownload = Vue.prototype.$apiUrl + "/downloads";
Vue.prototype.$apiAccessDomains = [
  {
    name: "eva.verlauf.at",
    key: "14123fj0qa392ur0f2jfc0q2ha3890dfh203f",
    url:
      "https://eva.verlauf.at/index.php?option=com_itraward&view=submission&task=submission.pushfile&format=json",
    redirect: "https://eva.verlauf.at/einreichung/#id#/image"
  },
  {
    name: "ooh-award.gewista.at",
    key: "ZNMbAAGwcgQSJ0Z7XKrK2fUg9TdSe0",
    url:
      "https://ooh-award.gewista.at/index.php?option=com_itraward&view=submission&task=submission.pushfile&format=json",
    redirect: "https://ooh-award.gewista.at/einreichung/#id#/image"
  }
];

Vue.mixin(GlobalFunctions);

Vue.config.devtools = true;
Vue.config.performance = true;

Vue.config.errorHandler = function(err, vm, info) {
  //console.log(err);
  //console.log(vm);
  //console.log(info);
};

Vue.config.warnHandler = function(msg, vm, info) {
  //console.log(msg);
  //console.log(vm);
  //console.log(info);
};

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
