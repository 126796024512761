<template>
  <div class="itr-footer">
    <footer>
      <div class="wrapper col-12">
        <div class="row"><div class="col-12 footer-top"></div></div>
        <div class="row">
          <div class="col-12 footer-bottom mt-2">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-8 pl-md-5 pt-2">
                <nav id="footer-menu">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <router-link to="/hilfe">{{ $t('footer.hilfe') }}</router-link>
                    </li>
                    <li class="list-inline-item">
                      <router-link to="/impressum">{{ $t('footer.impressum') }}</router-link>
                    </li>
                    <li class="list-inline-item">
                      <router-link to="/datenschutz">{{ $t('footer.datenschutz') }}</router-link>
                    </li>
                    <li class="list-inline-item">
                      <a href="#"
                         @click="setLocale"
                         locale="en"
                      >English</a>
                    </li>

                  </ul>
                </nav>
              </div>
              <div class="col-12 col-md-6 col-lg-4 pr-md-2">
                <div class="footer-logo">
                  <a
                    href="https://www.gewista.at"
                    title="Gewista Werbegesellschaft mbH"
                    target="_blank"
                    ><span class="logo-text">{{ $t('footer.einserviceder') }} </span
                    ><img :src="footerImg" alt="Logo Gewista"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ItrFooter",
  computed: {
    footerImg() {
      return require("../assets/images/gewista-footer.png");
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
  }
};
</script>

<style scoped lang="scss">
@import "../assets/css/settings.scss";

footer {
  padding-bottom: 1.5rem;
}

.footer-top {
  margin-top: 2px;
  border-bottom: 20px solid $color_footer;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  height: 0;
  width: 100px;
  transform: rotate(180deg);
}
#footer-menu {
  a {
    color: $color_footer_link !important;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  .list-inline {
    margin-left: 0.3rem;
  }
}

.footer-logo {
  margin-top: 5px;

  max-width: 300px;
  margin-left: auto;
  .logo-text {
    margin-bottom: 10px;
    color: $color_main_black;
    font-size: $h4-size;
    display: inline-block;
    position: relative;
    top: 0.1rem;
    left: 0;
  }
  img {
    display: inline-block;
    max-width: 160px;
  }
}

@media (max-width: $break-sm) {
  .footer-bottom {
    #footer-menu {
      text-align: center;
      .list-inline-item {
        display: block;
        margin-right: 0;
      }
    }
  }
}

@media (max-width: $break-md) {
  .footer-bottom {
    .footer-logo {
      text-align: center;
      margin-right: auto;
      margin-left: auto;
      .logo-text {
        display: block;
        text-align: center;
        width: 100%;
        bottom: -0.2rem;
      }
      img {
        display: block;
        max-width: 200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
