import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      meta: {
        title: "Gewista Previewbox"
      },
      component: () => import("./views/Home.vue")
    },
    {
      path: "/hilfe",
      name: "hilfe",
      meta: {
        title: "Hilfe - Gewista Previewbox"
      },
      component: () => import("./views/Hilfe.vue")
    },
    {
      path: "/impressum",
      name: "impressum",
      meta: {
        title: "Impressum - Gewista Previewbox"
      },
      component: () => import("./views/Impressum.vue")
    },
    {
      path: "/datenschutz",
      name: "datenschutz",
      meta: {
        title: "Datenschutz - Gewista Previewbox"
      },
      component: () => import("./views/Datenschutz.vue")
    },
    {
      path: "/plakat",
      name: "plakat",
      meta: {
        title: "Plakat - Gewista Previewbox"
      },
      component: () => import("./views/Plakat.vue")
    },
    {
      path: "/plakat/:id",
      name: "plakatId",
      meta: {
        title: "Plakat - Gewista Previewbox"
      },
      component: () => import("./views/ImageGallery.vue")
    },
    {
      path: "/citylight",
      name: "citylight",
      meta: {
        title: "City Light - Gewista Previewbox"
      },
      component: () => import("./views/CityLight.vue")
    },
    {
      path: "/citylight/:id",
      name: "citylightId",
      meta: {
        title: "City Light - Gewista Previewbox"
      },
      component: () => import("./views/ImageGallery.vue")
    },
    {
      path: "/premiumboard",
      name: "premiumboard",
      meta: {
        title: "Premium Board - Gewista Previewbox"
      },
      component: () => import("./views/PremiumBoard.vue")
    },
    {
      path: "/premiumboard/:id",
      name: "premiumboardId",
      meta: {
        title: "Premium Board - Gewista Previewbox"
      },
      component: () => import("./views/ImageGallery.vue")
    },
    {
      path: "/rollingboard",
      name: "rollingboard",
      meta: {
        title: "Rolling Board - Gewista Previewbox"
      },
      component: () => import("./views/RollingBoard.vue")
    },
    {
      path: "/digitalescitylight",
      name: "digitalmedia",
      meta: {
        title: "Digitales City Light - Gewista Previewbox"
      },
      component: () => import("./views/DigitalMedia.vue")
    },
    {
      path: "/premiumscreen",
      name: "premiumscreen",
      meta: {
        title: "Premium Screen - Gewista Previewbox"
      },
      component: () => import("./views/PremiumScreen.vue")
    },
    {
      path: "/upload/:type/:id",
      name: "upload",
      meta: {
        title: "Render - Gewista Previewbox"
      },
      component: () => import("./views/Upload.vue")
    },
    {
      path: "/download/:type/:id",
      name: "download",
      meta: {
        title: "Download - Gewista Previewbox"
      },
      component: () => import("./views/Download.vue")
    }
  ]
});
