<script>
import axios from "axios";

export default {
  name: "global-functions",
  methods: {
    createLocalJobId(jobType, jobSetting, forceIndex = null) {
      var prefix = this.$store.state.render.prefix[jobType];
      var nextIndex = this.getNextIndex(jobType);
      if (forceIndex !== null && forceIndex !== undefined) {
        nextIndex = forceIndex;
      }

      var jid = prefix + nextIndex;

      this.$store.dispatch("createJob", {
        index: nextIndex,
        type: jobType,
        id: jid,
        setting: jobSetting
      });
      return jid;
    },
    getNextIndex(jtype) {
      return this.$store.getters.getJobsByType(jtype).length;
    },
    createApiJob(jId, jType, jSetting) {
      return new Promise(process => {
        axios
          .post(
            this.$apiUrlJob +
              "?jobId=" +
              jId +
              "&jobType=" +
              jType +
              "&jobSetting=" +
              jSetting
          )
          .then(response => {
            if (jType !== "digitalmedia" && jType !== "rollingboard" && jType !== "premiumscreen") {
              this.$store.dispatch("startPoll", {
                id: jId,
                type: jType
              });
            }
            process();
          })
          .catch(error => {
            //throw new Error(error);
          });
      });
    },
    startApiJob(jId, jType, jSetting) {
      return new Promise(process => {
        axios
          .put(
            this.$apiUrlJob +
              "?jobId=" +
              jId +
              "&jobType=" +
              jType +
              "&jobSetting=" +
              jSetting +
              "&start=" +
              true
          )
          .then(response => {
            process();
          });
      });
    },
    getJobTitleBySetting(type, setting) {
      return this.$t('media.' + type + '.settingtitle.' + setting)
    },
    getJobSubtitleBySetting(type, setting) {
      return this.$t('media.' + type + '.settingsubtitle.' + setting)
    },
    getJobById(id) {
      var day = new Date();
      var url =
        this.$apiUrlDownload +
        "/" +
        day.getFullYear() +
        ("0" + (day.getMonth() + 1)).slice(-2) +
        ("0" + day.getDate()).slice(-2) +
        "/" +
        id +
        "/video.mp4";
      axios
        .get(url)
        .then(response => {
          return url;
        })
        .catch(error => {
          return false;
        });
    },
    postMessage(type, title, text) {
      var config = {
        timeout: 5000,
        titleMaxLength: 30,
        position: "leftBottom"
      };

      switch (type) {
        case "success":
          this.$snotify.success(text, title, config);
          break;
        case "warning":
          this.$snotify.warning(text, title, config);
          break;
        case "error":
          this.$snotify.error(text, title, config);
          break;
        case "info":
          this.$snotify.info(text, title, config);
          break;
      }
    },
    checkJobPoll() {
      for (var t in this.plakat) {
        if (!this.plakat.hasOwnProperty(t)) continue;
        if (
          this.plakat[t].status !== undefined &&
          this.plakat[t].status !== null &&
          this.plakat[t].status.renderedAt !== null &&
          this.plakat[t].poll === true
        ) {
          this.$store.dispatch("stopPoll", {
            type: "plakat",
            id: t
          });
        }
      }

      for (var u in this.rollingboard) {
        if (!this.rollingboard.hasOwnProperty(u)) continue;

        if (
          this.rollingboard[u].status !== undefined &&
          this.rollingboard[u].status !== null &&
          this.rollingboard[u].status.renderedAt !== null &&
          this.rollingboard[u].poll === true
        ) {
          this.$store.dispatch("stopPoll", {
            type: "rollingboard",
            id: u
          });
        }
      }

      for (var u in this.premiumscreen) {
        if (!this.premiumscreen.hasOwnProperty(u)) continue;

        if (
          this.premiumscreen[u].status !== undefined &&
          this.premiumscreen[u].status !== null &&
          this.premiumscreen[u].status.renderedAt !== null &&
          this.premiumscreen[u].poll === true
        ) {
          this.$store.dispatch("stopPoll", {
            type: "premiumscreen",
            id: u
          });
        }
      }

      for (var v in this.citylight) {
        if (!this.citylight.hasOwnProperty(v)) continue;

        if (
          this.citylight[v].status !== undefined &&
          this.citylight[v].status !== null &&
          this.citylight[v].status.renderedAt !== null &&
          this.citylight[v].poll === true
        ) {
          this.$store.dispatch("stopPoll", {
            type: "citylight",
            id: v
          });
        }
      }

      for (var u in this.premiumboard) {
        if (!this.premiumboard.hasOwnProperty(u)) continue;

        if (
            this.premiumboard[u].status !== undefined &&
            this.premiumboard[u].status !== null &&
            this.premiumboard[u].status.renderedAt !== null &&
            this.premiumboard[u].poll === true
        ) {
          this.$store.dispatch("stopPoll", {
            type: "premiumboard",
            id: u
          });
        }
      }

      for (var w in this.digitalmedia) {
        if (!this.digitalmedia.hasOwnProperty(w)) continue;

        if (
          this.digitalmedia[w].status !== undefined &&
          this.digitalmedia[w].status !== null &&
          this.digitalmedia[w].status.renderedAt !== null &&
          this.digitalmedia[w].poll === true
        ) {
          this.$store.dispatch("stopPoll", {
            type: "digitalmedia",
            id: w
          });
        }
      }
    }
  },
  computed: {
    plakat() {
      return this.$store.state.render.plakat;
    },
    rollingboard() {
      return this.$store.state.render.rollingboard;
    },
    premiumscreen() {
      return this.$store.state.render.premiumscreen;
    },
    citylight() {
      return this.$store.state.render.citylight;
    },
    premiumboard() {
      return this.$store.state.render.premiumboard;
    },
    digitalmedia() {
      return this.$store.state.render.digitalmedia;
    }
  }
};
</script>
