import Vue from "vue";
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const messages = {
    de: {
        base: {
            previewbox: 'Previewbox',
            back: 'Zurück',
            select: 'Auswählen',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            prepare: 'Vorbereiten',
        },
        menu: {
            plakat: 'Plakat',
            citylight: 'City Light',
            rollingboard: 'Rolling Board',
            premiumboard: 'Premium Board',
            digitalescitylight: 'Digitales City Light',
            premiumscreen: 'Premium Screen',
        },
        footer: {
            hilfe: 'Hilfe',
            impressum: 'Impressum',
            datenschutz: 'Datenschutz',
            einserviceder: 'Ein Service der',
        },
        upload: {
            selectordrop: '{fileType} auswählen oder hereinziehen',
            minresolution: 'Auflösung min. {filewidth}x{fileheight}px',
            rgb: '(Upload nur im RGB-Farbraum)',
            filetypebild: 'Bild',
            filetypevideo: 'Video',
            wait1: 'Ihr Video wird erstellt.',
            wait2: 'Bitte um etwas Geduld.',
            error: {
                ratio: {
                    title: "Seitenverhältnis prüfen!",
                    text: "Die hochgeladene Datei entspricht nicht den vorgegebenen Dimensionen",
                },
                maxfilesize: 'Maximal zulässige Filegröße von {{maxFilesize}} überschritten, bitte erneut versuchen',
                wrongfiletype: 'Ungültiger Filetyp',
                error: 'Fehler beim File-Upload',
            },
        },
        gallery: {
            bilddownloaden: 'Bild Downloaden',
            alledownloaden: 'Alle Downloaden',
        },
        previewimage: {
            videouploadsuccess: 'Video Upload erfolgreich!',
        },
        resultimage: {
            uploadsuccess: 'Upload erfolgreich',
        },
        media: {
            plakat: {
                title: 'Plakat',
                settingtitle: {
                    "8bogen": '8-Bogen',
                    "16bogen": '16-Bogen',
                    "24bogen": '24-Bogen',
                    "48bogen": '48-Bogen',
                    "dominanz": 'Dominanz',
                },
                settingsubtitle: {
                    "8bogen": 'Ihre 8-Bogen Plakate',
                    "16bogen": 'Ihre 16-Bogen Plakate',
                    "24bogen": 'Ihre 24-Bogen Plakate',
                    "48bogen": 'Ihre 48-Bogen Plakate',
                    "dominanz": 'Ihre Dominanz Plakate',
                }
            },
            rollingboard: {
                title: 'Rolling Board',
                settingtitle: {
                    "default": '',
                    "sujet1": 'Sujet 1',
                    "sujet2": 'Sujet 2',
                    "sujet3": 'Sujet 3',
                },
                settingsubtitle: {
                    "default": 'Ihr Rolling Board Video',
                }
            },
            premiumscreen: {
                title: 'Premium Screen',

                settingtitle: {
                    "default": '',
                    "sujet1": 'Sujet 1',
                    "sujet2": 'Sujet 2',
                    "sujet3": 'Sujet 3',
                },
                settingsubtitle: {
                    "default": 'Ihr Premium Screen Video',
                }
            },
            citylight: {
                title: 'City Light',
                settingtitle: {
                    "cl_normal": 'City Light',
                },
                settingsubtitle: {
                    "cl_normal": 'Ihre City Lights',
                    "cl_hoch": 'Ihre City Lights"',
                    "cl_litfass": 'Ihre Litfasssäulen City Lights',
                }
            },
            premiumboard: {
                title: 'Premium Board',
                settingtitle: {
                    "default": 'Premium Board',
                    "pb_normal": 'Premium Board',
                },
                settingsubtitle: {
                    "default": 'Ihr Premium Board',
                    "pb_normal": 'Ihr Premium Board Sujet',
                }
            },
            digitalmedia: {
                title: 'Digital Media Video',
                settingtitle: {
                    "default": '',
                },
                settingsubtitle: {
                    "default": 'Ihr Digital Media Video',
                }
            },
        },
        plakat: {
            title: 'Plakat',
            introtext: 'Hier können Sie Ihre Sujets hochladen und Ihre eigene Werbung auf verschiedenen Plakaten testen, speichern und präsentieren. Das Ergebnis steht Ihnen als Bilddownload zur Verfügung. Bitte laden Sie die Sujets in der angegebenen Mindestgröße hoch um Ergebnisse zu erzielen.',
            hochladen: 'Sujets hochladen',
            fertigtitle: 'Ihre Plakate',
            ihreeinreichung: 'Ihre Einreichung',
            zurueckeinreichung: 'Zurück zur Einreichung',
        },
        citylight: {
            title: 'City Light',
            introtext:
                '<p>' +
                'Hier können Sie Ihre Sujets hochladen und Ihre eigene Werbung am City Light testen. Bitte laden Sie die Sujets in der angegebenen Mindestgröße hoch um Ergebnisse zu erzielen.' +
                '</p>' +
                '<p>' +
                'Sie können bis zu 10 Sujets gleichzeitig hochladen. Die Sujets werden in allen Bildvarianten ausgegeben.' +
                '</p>',
            hochladen: 'Sujets hochladen',
            fertigtitle: 'Ihre City Lights',
            ihreeinreichung: 'Ihre Einreichung',
            zurueckeinreichung: 'Zurück zur Einreichung',
        },
        rollingboard: {
            title: 'Rolling Board',
            introtext: 'Hier können Sie Sujets hochladen und Ihre eigene Werbung am Rolling Board testen, speichern und präsentieren. Sie können verschiedene Standorte wählen. Das Ergebnis steht Ihnen als Bilddownload zur Verfügung. Bitte laden Sie die Sujets in der angegebenen Mindestgröße hoch um Ergebnisse zu erzielen.',
            hochladen: 'Sujets hochladen',
            standort: 'Standort wählen',
            fertigtitle: 'Ihre Rolling Boards',
            ihreeinreichung: 'Ihre Einreichung',
            zurueckeinreichung: 'Zurück zur Einreichung',
            standort1title: '1er Tag',
            standort2title: '1er Nacht',
            standort3title: '3er Tag',
            standort4title: '3er Nacht',
            standort5title: '2er Tag',
            standort6title: '2er Nacht',
            standort7title: '3er Video',
            vorherigerstandort: 'Vorheriger Standort',
            naechsterstandort: 'nächster Standort',
            videoerstellen: 'Video erstellen',
        },
        premiumboard: {
            title: 'Premium Board',
            introtext:
                '<p>' +
                'Hier können Sie Ihre Sujets hochladen und Ihre eigene Werbung am Rolling Board testen. Bitte laden Sie die Sujets in der angegebenen Mindestgröße hoch um Ergebnisse zu erzielen.' +
                '</p>' +
                '<p>' +
                'Sie können bis zu 10 Sujets gleichzeitig hochladen. Die Sujets werden in allen Bildvarianten ausgegeben.' +
                '</p>',
            hochladen: 'Sujet hochladen',
            fertigtitle: 'Ihre Premium Boards',
            ihreeinreichung: 'Ihre Einreichung',
            zurueckeinreichung: 'Zurück zur Einreichung',
        },
        digitalmedia: {
            title: 'Digitales City Light',
            introtext: 'Hier können Sie Ihren Spot hochladen und auf Single und Double Screen oder Rolltreppe als Video präsentieren. Erleben Sie Digital Out of Home in Wiens U-Bahnstationen oder auf der Oberfläche, alles ist möglich. Um beste Ergebnisse zu erzielen, laden Sie den Spot im 9:16 – Format hoch.',
            hochladen: 'Video hochladen',
            standort: 'Standort wählen',
            standort1title: 'Indoor Sommer',
            standort2title: 'Indoor Winter',
            standort3title: 'Rolltreppe Sommer',
            standort4title: '',
            standort5title: 'Outdoor Sommer',
            videoerstellen: 'Video erstellen',
        },
        premiumscreen: {
            title: 'Premium Screen',
            introtext: 'Hier können Sie Ihren Spot oder Ihr Sujet hochladen und Ihre eigene Werbung am digitalen Premium Screen testen, speichern und präsentieren. Das Ergebnis steht Ihnen als Videodownload im mp4-Format zur Verfügung. Bitte laden Sie die Sujets in der angegebenen Mindestgröße hoch um Ergebnisse zu erzielen.',
            hochladen: 'Video hochladen',
            standort: 'Standort wählen',

            standort1title: 'Reichsbrücke Tag',
            standort2title: 'Reichsbrücke Nacht',
            standort3title: 'Donaustadtstraße Tag',
            standort4title: 'Donaustadtstraße Nacht',
            standort5title: 'Wagramer Straße Tag',
            standort6title: 'Wagramer Straße Nacht',

            videoerstellen: 'Video erstellen',
        },
        download: {
            ihrvideo: 'Ihr {typeName} Video',
            download: 'Video Downloaden',
            neuesvideo: 'Neues Video erstellen',
            ineinreichnung: 'In Einreichung übernehmen',
        },
        home: {
            headline1: 'Sehen Sie es,',
            headline2: 'wie sie es sehen würden,',
            headline3: 'wenn es Out of Home ist.',
            introtext: 'Verhelfen Sie Ihrer kreativen Idee zum großen Auftritt bei der Präsentation. Ob am Plakat, City Light, Premium Board oder unseren digitalen Werbeträgern– unser attraktives und leicht bedienbares Tool macht den „Reality-Check“ Ihrer Entwürfe einfach und komfortabel. Mit der Previewbox können Sie Ihre Sujets virtuell auf den Gewista-Werbeträgern platzieren und erhalten so einen Eindruck von der Wirkung Ihrer Werbebotschaft – online, zum Download oder schlussendlich auch mit einem Ausdruck der verschiedensten Perspektiven.',
        },
        impressum: {
            title: 'Impressum',
            text: '<h5>Informationen nach §5 ECG</h5> ' +
                '<p> ' +
                '  <strong>Gewista Werbegesellschaft mbH</strong><br /> ' +
                '  Litfaßstraße 6, A-1031 Wien<br /> ' +
                '  Telefon: ' +
                '  <a href="tel:+431795970" title="Call (+43 1) 79 5 97-0" ' +
                '    >(+43 1) 79 5 97-0</a ' +
                '  ><br /> ' +
                '  Fax: (+43 1) 79 5 97-499<br /> ' +
                '  E-Mail: ' +
                '  <a ' +
                '    href="mailto:gewista@gewista.at" ' +
                '    title="Mail an gewista@gewista.at" ' +
                '    >gewista@gewista.at</a ' +
                '  > ' +
                '</p> ' +
                '<p> ' +
                '  Kammerzugehörigkeit: Fachverband Werbung<br /> ' +
                '  Firmenbuchnummer: 69991d HGW<br /> ' +
                '  UID-Nummer: ATU 15502301<br /> ' +
                '  Firmenbuchgericht: Handelsgericht Wien ' +
                '</p> ' +
                '<p> ' +
                '  Der www-Server der Gewista Werbegesellschaft mbH ist ein aktuelles ' +
                '  Service- und Informationsmedium der Gewista Werbegesellschaft mbH. ' +
                '</p> ' +
                '<h5>Allgemeine Geschäftsbedingungen</h5> ' +
                '<p> ' +
                '  Hier können Sie die aktuell gültigen Geschäftsbedingungen ' +
                '  herunterladen:<br /> ' +
                '  <a ' +
                '    href="http://gewista.at/uploads/GewistaAGBs1seitig_34221_DE.pdf" ' +
                '    title="Gewista AGB Download" ' +
                '    target="_blank" ' +
                '    >Download Allgemeine Geschäftsbedingungen</a ' +
                '  > ' +
                '  (PDF, 780 kB) ' +
                '</p> ' +
                '<h5>Copyright</h5> ' +
                '<p> ' +
                '  Alle Rechte vorbehalten. Vervielfältigung von Texten und Daten ' +
                '  einschließlich Speicherung und Nutzung auf optischen und ' +
                '  elektronischen Datenträgern nur im vertraglich vereinbarten Rahmen ' +
                '  oder mit vorheriger Zustimmung von Gewista Werbegesellschaft mbH. ' +
                '  Die Verwertung von Daten inklusive Einspeisung in Online-Dienste, ' +
                '  Databases oder Websites durch unberechtigte Dritte ist untersagt. ' +
                '</p> ' +
                '<h5>Haftungsausschluss</h5> ' +
                '<p> ' +
                '  Gewista Werbegesellschaft mbH haftet nicht für Schäden, die aus ' +
                '  inkorrekten oder verspäteten Inhalten oder aus Handlungen ' +
                '  resultieren, die im Vertrauen auf die Richtigkeit des Inhaltes ' +
                '  getätigt wurden. ' +
                '</p>',
        },
        hilfe: {
            title: 'Fragen & Hilfe',
            text: 'Wenn Sie Fragen, Wünsche und Anregungen zu unserer Previewbox ' +
                'haben, stehen wir Ihnen gerne zur Verfügung. Bitte schreiben Sie ' +
                'uns Ihr Anliegen an ' +
                '<a href="mailto:info@gewista.at" title="Mail and info@gewista.at" ' +
                '  >info@gewista.at</a ' +
                '>',
        },
        datenschutz: {
            title: 'Datenschutz',
            text: '<p> ' +
                '  Die Gewista Werbegesellschaft ist sich ihrer gro&szlig;en ' +
                '  Verantwortung bei der Verarbeitung personenbezogener Daten bewusst ' +
                '  und verpflichtet sich daher im Rahmen ihrer gesellschaftlichen ' +
                '  Verantwortung zur vollst&auml;ndigen Einhaltung von ' +
                '  Datenschutzrechten. Die Pers&ouml;nlichkeitsrechte und die ' +
                '  Privatsph&auml;re eines jeden Einzelnen zu wahren, ist f&uuml;r uns ' +
                '  oberste Priorit&auml;t im Umgang mit personenbezogenen Daten.<br /><br /> ' +
                '  Wir handeln hierbei nach den Grunds&auml;tzen von ' +
                '  Rechtm&auml;&szlig;igkeit, Transparenz, Zweckbindung, ' +
                '  Speicherbegrenzung und Datensicherheit.<br /><br /> ' +
                '  Die vorliegende Datenschutzerkl&auml;rung dient dazu, unseren ' +
                '  KundInnen und InteressentInnen &uuml;ber alle Aspekte der ' +
                '  Verarbeitung ihrer personenbezogenen Daten zu informieren ' +
                '  (Transparenz) und ihnen den Zugang zu ihren Rechten und ' +
                '  M&ouml;glichkeiten im Rahmen der Datenschutzbestimmungen zu ' +
                '  erleichtern.<br /> ' +
                '  Es werden auch jene Ma&szlig;nahmen beschrieben, die wir ergriffen ' +
                '  haben, um die Sicherheit und Vertraulichkeit der Daten zu ' +
                '  sch&uuml;tzen.<br /> ' +
                '  Die Verarbeitung personenbezogener Daten wird von uns ' +
                '  ausschlie&szlig;lich f&uuml;r die Zwecke erhoben und verarbeitet, ' +
                '  die vor der Erhebung der Daten festgelegt wurden. Wir verarbeiten ' +
                '  nur diejenigen personenbezogenen Daten, die f&uuml;r die Abwicklung ' +
                '  unserer Leistungen und f&uuml;r die Verwaltung von KundInnen und ' +
                '  InteressentInnen ben&ouml;tigt werden. Dies nur solange, wie die ' +
                '  Leistung bezogen wird oder wir durch gesetzliche Bestimmungen dazu ' +
                '  verpflichtet sind.<br /> ' +
                '  Die Verarbeitung aller personenbezogenen Daten erfolgt unter ' +
                '  strikter Beachtung der geltenden datenschutzrechtlichen ' +
                '  Bestimmungen. Personenbezogene Daten werden von uns weder ' +
                '  ver&ouml;ffentlicht, noch unberechtigt an Dritte weitergegeben. Die ' +
                '  Datenverarbeitung erfolgt ausschlie&szlig;lich im EU-Inland.<br /><br /> ' +
                '  Diese Datenschutzerkl&auml;rung gilt f&uuml;r unsere Website ' +
                '  http://www.gewista.at. Einzelne Seiten k&ouml;nnen Links auf andere ' +
                '  AnbieterInnen innerhalb und au&szlig;erhalb der Gewista enthalten, ' +
                '  auf die sich die Datenschutzerkl&auml;rung nicht erstreckt. F&uuml;r ' +
                '  diese Inhalte &uuml;bernehmen wir keine Haftung. ' +
                '</p> ' +
                '<h5>Rechtliche Grundlagen unserer Datenverarbeitungen</h5> ' +
                '<p> ' +
                '  Wir verarbeiten Daten von KundInnen als auch von InteressentInnen ' +
                '  und InformationsbezieherInnen, die uns ihre personenbezogenen Daten, ' +
                '  aufgrund ihrer Anfrage zur Verf&uuml;gung gestellt haben.<br /><br /> ' +
                '  Die Datenverarbeitungen beruhen auf den folgenden ' +
                '  Rechtsgrundlagen:<br /><br /> ' +
                '  1. Die Verarbeitung der Daten von KundInnen ist f&uuml;r die ' +
                '  Erf&uuml;llung eines Vertrags, dessen Vertragspartei sie sind, oder ' +
                '  zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, ' +
                '  die auf deren Anfrage erfolgen<br /> ' +
                '  2. Personenbezogenen Daten, die von den Betroffenen selbst ' +
                '  bereitgestellt wurden, wobei sie zu deren Verarbeitung f&uuml;r den ' +
                '  jeweiligen Zweck ausdr&uuml;cklich einwilligen. Diese Einwilligung ' +
                '  kann jederzeit widerrufen werden. ' +
                '</p> ' +
                '<h5>Auftragsverarbeiter</h5> ' +
                '<p> ' +
                '  Eine Auftragsdatenverarbeitung liegt vor, wenn ein ' +
                '  Auftragsverarbeiter mit der Verarbeitung personenbezogener Daten ' +
                '  beauftragt wird, ohne dass ihm die Verantwortung f&uuml;r den ' +
                '  zugeh&ouml;rigen Gesch&auml;ftsprozess &uuml;bertragen wird. In ' +
                '  diesen F&auml;llen schlie&szlig;en wir mit dem externen ' +
                '  Auftragsverarbeiter eine Vereinbarung &uuml;ber eine ' +
                '  Auftragsdatenverarbeitung ab. Dabei behalten wir die volle ' +
                '  Verantwortung f&uuml;r die datenschutzrechtlich korrekte ' +
                '  Durchf&uuml;hrung der Datenverarbeitung. Der Auftragsverarbeiter ' +
                '  darf personenbezogene Daten nur im Rahmen der Weisungen des ' +
                '  Verantwortlichen verarbeiten.<br /><br /> ' +
                '  Wir arbeiten nur mit Auftragsverarbeitern (wie z.B. Druckereien oder ' +
                '  Versandagenturen) zusammen, die hinreichend Garantien daf&uuml;r ' +
                '  bieten, dass geeignete technische und organisatorische ' +
                '  Ma&szlig;nahmen bei der Verarbeitung, im Einklang mit den ' +
                '  Anforderungen der DSGVO erfolgen und den Schutz der ' +
                '  personenbezogenen Daten gew&auml;hrleisten. Die Verarbeitung durch ' +
                '  einen Auftragsverarbeiter erfolgt nur auf Grundlage eines Vertrages ' +
                '  mit uns, welcher die Dauer, die Art und den Zweck der Verarbeitung ' +
                '  genau festlegt. Alle Auftragsverarbeiter, die personenbezogene Daten ' +
                '  verarbeiten und &uuml;berpr&uuml;fen, kontrollieren ' +
                '  regelm&auml;&szlig;ig, ob die datenschutzrechtlichen Bestimmungen ' +
                '  eingehalten werden. ' +
                '</p> ' +
                '<h5>Datenarten und Zweck der Verarbeitung</h5> ' +
                '<p> ' +
                '  Personenbezogene Daten unserer KundInnen und InteressentInnen werden ' +
                '  zu den Zwecken der Leistungs- und Informationsbereitstellung von uns ' +
                '  erhoben und verarbeitet. Die personenbezogenen Daten werden f&uuml;r ' +
                '  die Dauer ihres Leistungs- oder Informationsbezugs und solange noch ' +
                '  Anspr&uuml;che daraus bestehen k&ouml;nnen oder gesetzliche ' +
                '  Bestimmungen die Verarbeitung erfordern, gespeichert ' +
                '</p> ' +
                '<h5>Dauer der Datenspeicherung</h5> ' +
                '<p> ' +
                '  Wir speichern personenbezogene Daten nur solange, wie sie f&uuml;r ' +
                '  den Zweck der Verarbeitung ben&ouml;tigt werden und gesetzliche ' +
                '  Anspr&uuml;che bestehen k&ouml;nnen, bzw. solange es uns gesetzliche ' +
                '  Bestimmungen vorschreiben. Danach werden sie unwiderruflich ' +
                '  gel&ouml;scht.<br /><br /> ' +
                '  Zum Beispiel m&uuml;ssen aufgrund gesetzlicher Bestimmungen ' +
                '  (Aufbewahrungspflichten) des Unternehmensgesetzbuches (&sect; 212 ' +
                '  UGB) und der Bundesabgabenordnung (&sect; 132 BAO) f&uuml;r ' +
                '  Rechnungs- und Finanzdaten die betreffenden personenbezogenen Daten ' +
                '  f&uuml;r 7 Jahre gespeichert werden.<br /><br /> ' +
                '  Erhebung und Verarbeitung von personenbezogenen Daten bei Besuch ' +
                '  unserer Website<br /><br /> ' +
                '  Wenn Sie unsere Website besuchen, speichern unsere Webserver ' +
                '  tempor&auml;r jeden Zugriff in einer Protokolldatei. Folgenden Daten ' +
                '  werden dabei erfasst und f&uuml;r 6 Monate gespeichert: ' +
                '</p> ' +
                '<ul> ' +
                '  <li>IP-Adresse des anfragenden Rechners</li> ' +
                '  <li>Datum und Uhrzeit des Zugriffs</li> ' +
                '  <li>Name und URL der abgerufenen Daten</li> ' +
                '  <li>&Uuml;bertragene Datenmenge</li> ' +
                '  <li>Meldung, ob der Abruf erfolgreich war</li> ' +
                '  <li> ' +
                '    Erkennungsdaten des verwendeten Browser- und Betriebssystems ' +
                '  </li> ' +
                '</ul> ' +
                '<p> ' +
                '  Die Verarbeitung dieser Daten erfolgt zum Zweck, die Nutzung der ' +
                '  Website zu erm&ouml;glichen (Verbindungsaufbau), der ' +
                '  Systemsicherheit, der technischen Administration der ' +
                '  Netzinfrastruktur sowie zur Optimierung des Internetangebotes. Die ' +
                '  IP-Adresse wird nur bei Angriffen auf unsere Netzinfrastruktur ' +
                '  ausgewertet. ' +
                '</p> ' +
                '<h5>Cookies</h5> ' +
                '<p> ' +
                '  Unsere Website verwendet so genannte Cookies. Cookies richten auf ' +
                '  Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies ' +
                '  dienen dazu, unser Angebot nutzerfreundlicher, effektiver und ' +
                '  sicherer zu machen. Dabei handelt es sich um kleine Textdateien, die ' +
                '  mit Hilfe des Browsers auf Ihrem Endger&auml;t abgelegt werden.<br />Wir ' +
                '  nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. ' +
                '  Einige Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie ' +
                '  diese l&ouml;schen. Sie erm&ouml;glichen es uns, Ihren Browser beim ' +
                '  n&auml;chsten Besuch wiederzuerkennen.<br /> ' +
                '  Wenn Sie dies nicht w&uuml;nschen, so k&ouml;nnen Sie Ihren Browser ' +
                '  so einrichten, dass er Sie &uuml;ber das Setzen von Cookies ' +
                '  informiert und Sie dies nur im Einzelfall erlauben. Bei der ' +
                '  Deaktivierung von Cookies kann die Funktionalit&auml;t unserer ' +
                '  Website eingeschr&auml;nkt sein. ' +
                '</p> ' +
                '<h5>Web-Analyse - Google Analytics</h5> ' +
                '<p> ' +
                '  Diese Website benutzt Google Analytics, einen Webanalysedienst der ' +
                '  Google Inc. (&bdquo;Google&ldquo;). Google Analytics verwendet sog. ' +
                '  &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem Computer ' +
                '  gespeichert werden und die eine Analyse der Benutzung der Webseite ' +
                '  durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten ' +
                '  Informationen &uuml;ber Ihre Benutzung der Webseite werden in der ' +
                '  Regel an einen Server von Google in den USA &uuml;bertragen und dort ' +
                '  gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser ' +
                '  &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google ' +
                '  zusammengef&uuml;hrt.<br /> ' +
                '  Im Auftrag des Betreibers dieser Website wird Google diese ' +
                '  Information benutzen, um Ihre Nutzung der Webseite auszuwerten, um ' +
                '  Reports &uuml;ber die Webseiten-Aktivit&auml;ten zusammenzustellen ' +
                '  und um weitere mit der Webseiten-Nutzung und der Internetnutzung ' +
                '  verbundene Dienstleistungen gegen&uuml;ber dem Webseitenbetreiber zu ' +
                '  erbringen. Sie k&ouml;nnen die Speicherung der Cookies durch eine ' +
                '  entsprechende Einstellung Ihrer Browser-Software verhindern; wir ' +
                '  weisen Sie jedoch darauf hin, dass Sie in diesem Fall ' +
                '  m&ouml;glicherweise nicht s&auml;mtliche Funktionen dieser Webseite ' +
                '  vollumf&auml;nglich werden nutzen k&ouml;nnen.<br /> ' +
                '  Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das ' +
                '  Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten ' +
                '  (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser ' +
                '  Daten durch Google verhindern, indem sie das unter dem folgenden ' +
                '  Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: ' +
                '  http://tools.google.com/dlpage/gaoptout?hl=de. Alternativ zum ' +
                '  Browser-Add-On, insbesondere bei Browsern auf mobilen ' +
                '  Endger&auml;ten, k&ouml;nnen Sie die Erfassung durch Google ' +
                '  Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es ' +
                '  wird ein Opt-Out-Cookie gesetzt, das die zuk&uuml;nftige Erfassung ' +
                '  Ihrer Daten beim Besuch dieser Website verhindert. Der ' +
                '  Opt-Out-Cookie gilt nur in diesem Browser und nur f&uuml;r unsere ' +
                '  Website und wird auf Ihrem Ger&auml;t abgelegt. L&ouml;schen Sie die ' +
                '  Cookies in diesem Browser, m&uuml;ssen Sie das Opt-Out-Cookie erneut ' +
                '  setzen. [Anm. Hinweise zur Einbindung des Opt-Out-Cookie finden Sie ' +
                '  unter: ' +
                '  <a ' +
                '    href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable" ' +
                '    target="_blank" ' +
                '    >https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable</a>' +
                '  ].<br /> ' +
                '  Wir nutzen Google Analytics weiterhin dazu, Daten aus ' +
                '  Double-Click-Cookies und auch AdWords zu statistischen Zwecken ' +
                '  auszuwerten. Sollten Sie dies nicht w&uuml;nschen, k&ouml;nnen Sie ' +
                '  dies &uuml;ber den Anzeigenvorgaben-Manager (<a ' +
                '    href="http://www.google.com/settings/ads/onweb/?hl=de" ' +
                '    target="_blank" ' +
                '    >http://www.google.com/settings/ads/onweb/?hl=de</a>) ' +
                '   deaktivieren. ' +
                '</p> ' +
                '<h5>Eyepin &ndash; newsletter</h5> ' +
                '<p> ' +
                '  Wir verwenden das Newsletter-tool von eyepin. Die Newsletter der ' +
                '  eyepin GmbH, Billrothstra&szlig;e 52, 1190 Wien, enthalten ' +
                '  sogenannte Z&auml;hlpixel. Ein Z&auml;hlpixel ist eine ' +
                '  Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im ' +
                '  HTML-Format versendet werden, um eine Logdatei-Aufzeichnung und eine ' +
                '  Logdatei-Analyse zu erm&ouml;glichen. Dadurch kann eine statistische ' +
                '  Auswertung des Erfolges oder Misserfolges von ' +
                '  Online-Marketing-Kampagnen durchgef&uuml;hrt werden. Anhand des ' +
                '  eingebetteten Z&auml;hlpixels kann die eyepin GmbH erkennen, ob und ' +
                '  wann eine E-Mail von einer betroffenen Person ge&ouml;ffnet wurde. ' +
                '  Weiters wird &uuml;ber das Newsletter-Tracking erhoben, welche in ' +
                '  der E-Mail befindlichen Links von der betroffenen Person aufgerufen ' +
                '  wurden.<br /> ' +
                '  Solche &uuml;ber die in den Newslettern enthaltenen Z&auml;hlpixel ' +
                '  und das Newsletter-Tracking erhobenen personenbezogenen Daten, ' +
                '  werden von dem f&uuml;r die Verarbeitung Verantwortlichen ' +
                '  gespeichert und ausgewertet, um den Newsletterversand zu optimieren ' +
                '  und den Inhalt zuk&uuml;nftiger Newsletter noch besser den ' +
                '  Interessen der betroffenen Person anzupassen. Diese ' +
                '  personenbezogenen Daten werden nicht an Dritte weitergegeben. ' +
                '  Betroffene Personen sind jederzeit berechtigt, die ' +
                '  diesbez&uuml;gliche gesonderte, &uuml;ber das ' +
                '  Double-Opt-In-Verfahren abgegebene Einwilligungserkl&auml;rung ' +
                '  &uuml;ber die Abmeldung vom Erhalt des Newsletters zu widerrufen. ' +
                '  Nach einem Widerruf werden keinerlei weitere Daten erhoben und die ' +
                '  Adresse wird auf eine Sperrliste gesetzt, um die Abmeldung zu ' +
                '  dokumentieren und weitere Zusendungen zu verhindern.<br /> ' +
                '  Die Datenschutzerkl&auml;rung der eyepin GmbH finden Sie unter ' +
                '  <a ' +
                '    href="https://support.eyepin.com/hc/de/articles/360001151006-Datenschutz-bei-eyepin" ' +
                '    target="_blank" ' +
                '    >https://support.eyepin.com/hc/de/articles/360001151006-Datenschutz-bei-eyepin</a> ' +
                '</p> ' +
                '<h5>Datensicherheitsma&szlig;nahmen</h5> ' +
                '<p> ' +
                '  Die personenbezogenen Daten, die von uns verarbeitet werden, werden ' +
                '  mit besonderer Sorgfalt sowohl auf technischer als auch ' +
                '  organisatorischer Ebene aufbewahrt und gesichert. Sie sind vor ' +
                '  zuf&auml;lliger oder unrechtm&auml;&szlig;iger Zerst&ouml;rung und ' +
                '  vor Verlust gesch&uuml;tzt und wir stellen sicher, dass ihre ' +
                '  Verwendung ordnungsgem&auml;&szlig; erfolgt und dass die Daten ' +
                '  Unbefugten nicht zug&auml;nglich sind.<br /> ' +
                '  Alle unsere Auftragsverarbeiter sind aufgrund einer vertraglichen ' +
                '  Vereinbarung mit uns dazu verpflichtet, ebenfalls alle technischen ' +
                '  und organisatorischen Ma&szlig;nahmen f&uuml;r eine sichere ' +
                '  Verarbeitung zu ergreifen. Dies wird regelm&auml;&szlig;ig von einem ' +
                '  unserer Verantwortlichen &uuml;berpr&uuml;ft. ' +
                '</p> ' +
                '<h5>Datenschutzbeauftragter</h5> ' +
                '<p> ' +
                '  Der Datenschutzbeauftragte arbeitet eng mit der obersten ' +
                '  Organisationsebene zusammen und ist erster Ansprechpartner in Fragen ' +
                '  des Datenschutzes und der Datensicherheit. Sie finden sich in ' +
                '  regelm&auml;&szlig;igen Abst&auml;nden zusammen, um Fragen der ' +
                '  Datensicherheit und des Datenschutzes zu behandeln.<br /> ' +
                '  Er arbeitet im Bedarfsfall mit der Aufsichtsbeh&ouml;rde zusammen ' +
                '  und fungiert als Ansprechpartner f&uuml;r die Aufsichtsbeh&ouml;rde ' +
                '  in, mit der Verarbeitung personenbezogener Daten ' +
                '  zusammenh&auml;ngenden Fragen, einschlie&szlig;lich der vorherigen ' +
                '  Konsultation. Betroffene Personen k&ouml;nnen den ' +
                '  Datenschutzbeauftragten zu allen mit der Verarbeitung ihrer ' +
                '  personenbezogenen Daten und mit der Wahrnehmung ihrer Rechte im ' +
                '  Zusammenhang stehenden Fragen zu Rate ziehen.<br /> ' +
                '  Wir stellen sicher, dass der Datenschutzbeauftragte bei der ' +
                '  Erf&uuml;llung seiner Aufgaben keine Anweisungen erh&auml;lt. Die ' +
                '  Kontaktdaten unseres Datenschutzbeauftragten befinden sich in den ' +
                '  Kontaktinformationen am Ende der Datenschutzerkl&auml;rung. ' +
                '</p> ' +
                '<h5>Meldungsverpflichtung</h5> ' +
                '<p> ' +
                '  Im Fall einer Verletzung des Schutzes personenbezogener Daten sin ' +
                '  wir verpflichtet unverz&uuml;glich die Datenschutzbeh&ouml;rde von ' +
                '  dieser Verletzung zu benachrichtigen. Ist anzunehmen, dass durch ' +
                '  eine solche Verletzung Personen in ihrer Privatsph&auml;re oder die ' +
                '  personenbezogenen Daten selbst beeintr&auml;chtigt werden, kann die ' +
                '  Datenschutzbeh&ouml;rde - nach Ber&uuml;cksichtigung der ' +
                '  wahrscheinlichen nachteiligen Auswirkungen der Verletzung &ndash; ' +
                '  uns auffordern, eine Benachrichtigung der betroffenen Personen ' +
                '  durchzuf&uuml;hren. ' +
                '</p> ' +
                '<h5>Information &uuml;ber Betroffenenrechte</h5> ' +
                '<p> ' +
                '  Als Betroffene/r steht Ihnen ein Auskunftsrecht bez&uuml;glich der ' +
                '  &uuml;ber Sie gespeicherten personenbezogenen Daten, ein Recht auf ' +
                '  Richtigstellung unrichtiger Daten, auf Einschr&auml;nkung und ' +
                '  Widerspruch der Verarbeitung und auf L&ouml;schung zu. Um diese ' +
                '  Rechte aus&uuml;ben zu k&ouml;nnen, m&uuml;ssen Sie in geeigneter ' +
                '  Form ihre Identit&auml;t nachweisen.<br /> ' +
                '  Unsere Auskunft wird die verarbeiteten Daten, die Informationen ' +
                '  &uuml;ber deren Herkunft, allf&auml;llige Empf&auml;nger oder ' +
                '  Empf&auml;ngerkreise von &Uuml;bermittlungen, den Zweck der ' +
                '  Datenverwendung sowie die Rechtsgrundlagen hierf&uuml;r in allgemein ' +
                '  verst&auml;ndlicher Form anf&uuml;hren. Auf Ihr Verlangen sind auch ' +
                '  Namen und Adressen von Auftragsverarbeitern bekannt zu geben.<br /> ' +
                '  Als Auskunftswerber haben Sie am Auskunftsverfahren in einem Ihnen ' +
                '  zumutbaren Ausma&szlig; mitzuwirken, um ungerechtfertigten und ' +
                '  unverh&auml;ltnism&auml;&szlig;igen Aufwand beim Verantwortlichen ' +
                '  der Datenverarbeitung zu vermeiden.<br /> ' +
                '  Innerhalb von einem Monat nach Einlangen Ihres Begehrens werden wir ' +
                '  die Auskunft erteilen oder schriftlich begr&uuml;nden, warum sie ' +
                '  nicht oder nicht vollst&auml;ndig erteilt werden kann.<br /> ' +
                '  Zur Auskunft &uuml;ber Ihre personenbezogenen Daten beziehungsweise ' +
                '  deren Richtigstellung oder L&ouml;schung oder wenn Sie weitergehende ' +
                '  Fragen &uuml;ber die Verwendung Ihrer uns &uuml;berlassenen ' +
                '  personenbezogenen Daten haben, kontaktieren Sie bitte unseren ' +
                '  Datenschutzbeauftragten. Die Kontaktm&ouml;glichkeiten finden Sie am ' +
                '  Ende dieser Datenschutzerkl&auml;rung. ' +
                '</p> ' +
                '<h5>Die Datenschutzbeh&ouml;rde (DSB)</h5> ' +
                '<p> ' +
                '  Im Falle einer vermeintlichen Unzul&auml;nglichkeit des Schutzes ' +
                '  personenbezogener Daten besteht f&uuml;r Betroffene die ' +
                '  M&ouml;glichkeit Beschwerde bei der &ouml;sterreichischen ' +
                '  Datenschutzbeh&ouml;rde (<a ' +
                '    href="http://www.dsb.gv.at" ' +
                '    target="_blank" ' +
                '    >http://www.dsb.gv.at</a ' +
                '  >) zu erheben. ' +
                '</p> ' +
                '<h5>&Auml;nderung dieser Datenschutzerkl&auml;rung</h5> ' +
                '<p> ' +
                '  Da diese Hinweise der jeweils aktuellen Rechtslage unterliegen und ' +
                '  unsere Leistungen fortlaufend weiterentwickelt werden, behalten wir ' +
                '  uns vor, diese Datenschutzerkl&auml;rung k&uuml;nftig entsprechend ' +
                '  zu &auml;ndern. Wir empfehlen, diese Datenschutzerkl&auml;rung ' +
                '  regelm&auml;&szlig;ig zu lesen, um &uuml;ber den Schutz von uns ' +
                '  erfassten personenbezogenen Daten auf dem Laufenden zu bleiben.<br /><br /> ' +
                '  Der Verantwortliche ist die Gewista Werbegesellschaft mbH, ' +
                '  <br />Adresse: Litfa&szlig;stra&szlig;e 6, 1031 Wien, <br />E-Mail: ' +
                '  <a ' +
                '    href="mailto:gewista@gewista.at" ' +
                '    title="Mail an gewista@gewista.at" ' +
                '    >gewista@gewista.at</a ' +
                '  ><br /><br /> ' +
                '  Kontaktadresse des Datenschutzbeauftragten:<br /> ' +
                '  <a href="mailto:datenschutzbeauftragter@gewista.at" ' +
                '    >datenschutzbeauftragter@gewista.at</a ' +
                '  > ' +
                '</p>',
        },
    },


















    en: {
        base: {
            previewbox: 'Previewbox',
            back: 'Back',
            select: 'Select',
            delete: 'Delete',
            cancel: 'Cancel',
            prepare: 'Prepare',

        },
        menu: {
            plakat: 'Poster',
            citylight: 'City Light',
            rollingboard: 'Rolling Board',
            premiumboard: 'Premium Board',
            digitalescitylight: 'Digital City Light',
            premiumscreen: 'Premium Screen',
        },
        footer: {
            help: 'help',
            imprint: 'Imprint',
            datenschutz: 'Data protection',
            einserviceder: 'A service of',
        },
        upload: {
            selectordrop: 'Select or drag in {fileType}',
            minresolution: 'Resolution min. {filewidth}x{fileheight}px',
            rgb: '(upload in RGB color space only)',
            filetypeimage: 'Image',
            filetypevideo: 'Video',
            wait1: 'Your video is being created',
            wait2: 'Please be patient',
            error: {
                ratio: {
                    title: 'Check aspect ratio!',
                    text: 'The uploaded file does not match the specified dimensions',
                },
                maxfilesize: 'Maximum allowed file size of {{maxFilesize}} exceeded, please try again',
                wrongfiletype: 'Invalid file type',
                error: 'File upload error',
            },
        },
        gallery: {
            bilddownloaden: 'Download image',
            alledownloaden: 'Download all',
        },
        previewimage: {
            videouploadsuccess: 'Video Upload Successful!'
        },
        resultimage: {
            uploadsuccess: 'Upload successful',
        },
        media: {
            plakat: {
                title: 'Poster',
                settingtitle: {
                    "8bogen": '8-sheet',
                    "16bogen": '16-sheet',
                    "24bogen": '24-sheet',
                    "48bogen": '48-sheet',
                    "dominanz": 'Dominance',
                },
                settingsubtitle: {
                    "8bogen": 'Your 8-sheet posters',
                    "16bogen": 'Your 16-sheet posters',
                    "24bogen": 'Your 24-sheet posters',
                    "48bogen": 'Your 48-sheet posters',
                    "dominanz": 'Your dominance posters',
                }
            },
            rollingboard: {
                title: 'Rolling Board',
                settingtitle: {
                    "default": '',
                    "sujet1": 'Sujet 1',
                    "sujet2": 'Sujet 2',
                    "sujet3": 'Sujet 3',
                },
                settingsubtitle: {
                    "default": 'Your Rolling Board Video',
                }
            },
            premiumboard: {
                title: 'Premium Board',
                settingtitle: {
                    "default": '',
                    "pb_normal": 'Sujet',
                },
                settingsubtitle: {
                    "default": 'Your Premium Board',
                    "pb_normal": 'Your Premium Board Sujet',
                }
            },
            citylight: {
                title: 'City Light',
                settingtitle: {
                    "cl_normal": 'City Light',
                },
                settingsubtitle: {
                    "cl_normal": 'Your City Lights',
                    "cl_hoch": 'Your City Lights"',
                    "cl_litfass": 'Your City Lights Litfass',
                }
            },
            digitalmedia: {
                title: 'Digital Media Video',
                settingtitle: {
                    "default": '',
                },
                settingsubtitle: {
                    "default": 'Your Digital Media Video',
                }
            },
        },
        download: {
            ihrvideo: 'Your {typeName} video',
            download: 'Download video',
            neuesvideo: 'Create new video',
            ineinreichnung: 'Apply to Submission',
        },

        plakat: {
            title: 'Poster',
            introtext: 'Here, you can upload your subjects with the option to test, save and display your own advertising messages on a variety of Gewista posters.  The result of this upload is available to you as an image download.  For proper results, please upload your subjects within the specified minimum size.',
            hochladen: 'Upload sujets',
            fertigtitle: 'Your posters',
            ihreeinreichung: 'Your submission',
            zurueckeinreichung: 'return to submission',
        },
        citylight: {
            title: 'City Light',
            introtext: '<p>' +
                'Here, you can upload your subjects and test your advertising impact on Gewista City Lights.  For proper results, please upload your subjects within the specified minimum size.' +
                '</p>' +
                '<p>' +
                'You can upload up to 10 subjects at one time.  Output (download) will be available in all Gewista image variations.' +
                '</p>',
            hochladen: 'Upload sujets',
            fertigtitle: 'Your posters',
            ihreeinreichung: 'Your submission',
            zurueckeinreichung: 'return to submission',
        },
        rollingboard: {
            title: 'Rolling Board',
            introtext: 'Here, you can upload your subjects and then test, evaluate, save and display your ads on the Gewista Rolling Board with a choice of different locations.  The result is available to you as an image download.  For proper results, please upload subjects within the specified minimum size.',
            hochladen: 'Upload sujets',
            standort: 'Choose location',
            fertigtitle: 'Your Rolling Boards',
            ihreeinreichung: 'Your submission',
            zurueckeinreichung: 'return to submission',
            standort1title: '1 Day',
            standort2title: '1 Night',
            standort3title: '3 Day',
            standort4title: '3 Night',
            standort5title: '2 Day',
            standort6title: '2 Night',
            standort7title: '3 Video',
            vorherigerstandort: 'previous location',
            naechsterstandort: 'next location',
            videoerstellen: 'create video',
        },
        premiumboard: {
            title: 'Premium Board',
            introtext: '<p>' +
                'Here, you can upload your subjects and test your advertising impact on Gewista Premium Board.  For proper results, please upload your subjects within the specified minimum size.' +
                '</p>' +
                '<p>' +
                'You can upload up to 10 subjects at one time.  Output (download) will be available in all Gewista image variations.' +
                '</p>',
            hochladen: 'Upload sujets',
            fertigtitle: 'Your Premium Boards',
            ihreeinreichung: 'Your submission',
            zurueckeinreichung: 'return to submission',
        },
        digitalmedia: {
            title: 'Digitales City Light',
            introtext: 'Here, you can upload your ad spot and display it on a single or double screen, or on an escalator wall, as a video.  Whether underground in Vienna’s subway stations or above-ground on the street level, you can experience and evaluate the variations of Gewista’s Out of Home advertising.  For best results, upload your ad spot in the 9:16 format ratio.',
            hochladen: 'Upload video',
            standort: 'Choose location',
            standort1title: 'Indoor Summer',
            standort2title: 'Indoor Winter',
            standort3title: 'Rolltreppe Summer',
            standort4title: '',
            standort5title: 'Outdoor Summer',
            videoerstellen: 'create video',
        },
        premiumscreen: {
            title: 'Premium Screen',
            introtext: 'Here, you can upload your ad spot or subject and then test, evaluate, save and display it on the Gewista Premium Screen.  The result is available to you as a video download in the MP4 format.  For proper results, please upload your subjects within the specified minimum size.',
            hochladen: 'Upload video',
            standort: 'Choose location',

            standort1title: 'Reichsbrücke Day',
            standort2title: 'Reichsbrücke Night',
            standort3title: 'Donaustadtstraße Day',
            standort4title: 'Donaustadtstraße Night',
            standort5title: 'Wagramer Straße Day',
            standort6title: 'Wagramer Straße Night',

            videoerstellen: 'create video',
        },
        home: {
            headline1: 'See it,',
            headline2: 'as it would look,',
            headline3: 'when it’s Out of Home.',
            introtext: 'Present your creative ideas with a grand entrance.  Whether on a poster, City Light, Premium Board or our various digital advertising media, our attractive and easy-to-use tool, the Preview Box, gives you a simple and convenient “reality check” of your designs.  Using the Gewista Preview Box, you can virtually display your messages on various Gewista advertising media and actually see and evaluate the impact of your message -- online, for download, or for a print-out of your creative concepts.',
        },
        impressum: {
            title: 'Impressum',
            text: '<h5>Informationen nach §5 ECG</h5> ' +
                '<p> ' +
                '  <strong>Gewista Werbegesellschaft mbH</strong><br /> ' +
                '  Litfaßstraße 6, A-1031 Wien<br /> ' +
                '  Telefon: ' +
                '  <a href="tel:+431795970" title="Call (+43 1) 79 5 97-0" ' +
                '    >(+43 1) 79 5 97-0</a ' +
                '  ><br /> ' +
                '  Fax: (+43 1) 79 5 97-499<br /> ' +
                '  E-Mail: ' +
                '  <a ' +
                '    href="mailto:gewista@gewista.at" ' +
                '    title="Mail an gewista@gewista.at" ' +
                '    >gewista@gewista.at</a ' +
                '  > ' +
                '</p> ' +
                '<p> ' +
                '  Kammerzugehörigkeit: Fachverband Werbung<br /> ' +
                '  Firmenbuchnummer: 69991d HGW<br /> ' +
                '  UID-Nummer: ATU 15502301<br /> ' +
                '  Firmenbuchgericht: Handelsgericht Wien ' +
                '</p> ' +
                '<p> ' +
                '  Der www-Server der Gewista Werbegesellschaft mbH ist ein aktuelles ' +
                '  Service- und Informationsmedium der Gewista Werbegesellschaft mbH. ' +
                '</p> ' +
                '<h5>Allgemeine Geschäftsbedingungen</h5> ' +
                '<p> ' +
                '  Hier können Sie die aktuell gültigen Geschäftsbedingungen ' +
                '  herunterladen:<br /> ' +
                '  <a ' +
                '    href="http://gewista.at/uploads/GewistaAGBs1seitig_34221_DE.pdf" ' +
                '    title="Gewista AGB Download" ' +
                '    target="_blank" ' +
                '    >Download Allgemeine Geschäftsbedingungen</a ' +
                '  > ' +
                '  (PDF, 780 kB) ' +
                '</p> ' +
                '<h5>Copyright</h5> ' +
                '<p> ' +
                '  Alle Rechte vorbehalten. Vervielfältigung von Texten und Daten ' +
                '  einschließlich Speicherung und Nutzung auf optischen und ' +
                '  elektronischen Datenträgern nur im vertraglich vereinbarten Rahmen ' +
                '  oder mit vorheriger Zustimmung von Gewista Werbegesellschaft mbH. ' +
                '  Die Verwertung von Daten inklusive Einspeisung in Online-Dienste, ' +
                '  Databases oder Websites durch unberechtigte Dritte ist untersagt. ' +
                '</p> ' +
                '<h5>Haftungsausschluss</h5> ' +
                '<p> ' +
                '  Gewista Werbegesellschaft mbH haftet nicht für Schäden, die aus ' +
                '  inkorrekten oder verspäteten Inhalten oder aus Handlungen ' +
                '  resultieren, die im Vertrauen auf die Richtigkeit des Inhaltes ' +
                '  getätigt wurden. ' +
                '</p>',
        },
        hilfe: {
            title: 'Fragen & Hilfe',
            text: 'Wenn Sie Fragen, Wünsche und Anregungen zu unserer Previewbox ' +
                'haben, stehen wir Ihnen gerne zur Verfügung. Bitte schreiben Sie ' +
                'uns Ihr Anliegen an ' +
                '<a href="mailto:info@gewista.at" title="Mail and info@gewista.at" ' +
                '  >info@gewista.at</a ' +
                '>',
        },
        datenschutz: {
            title: 'Datenschutz',
            text: '<p> ' +
                '  Die Gewista Werbegesellschaft ist sich ihrer gro&szlig;en ' +
                '  Verantwortung bei der Verarbeitung personenbezogener Daten bewusst ' +
                '  und verpflichtet sich daher im Rahmen ihrer gesellschaftlichen ' +
                '  Verantwortung zur vollst&auml;ndigen Einhaltung von ' +
                '  Datenschutzrechten. Die Pers&ouml;nlichkeitsrechte und die ' +
                '  Privatsph&auml;re eines jeden Einzelnen zu wahren, ist f&uuml;r uns ' +
                '  oberste Priorit&auml;t im Umgang mit personenbezogenen Daten.<br /><br /> ' +
                '  Wir handeln hierbei nach den Grunds&auml;tzen von ' +
                '  Rechtm&auml;&szlig;igkeit, Transparenz, Zweckbindung, ' +
                '  Speicherbegrenzung und Datensicherheit.<br /><br /> ' +
                '  Die vorliegende Datenschutzerkl&auml;rung dient dazu, unseren ' +
                '  KundInnen und InteressentInnen &uuml;ber alle Aspekte der ' +
                '  Verarbeitung ihrer personenbezogenen Daten zu informieren ' +
                '  (Transparenz) und ihnen den Zugang zu ihren Rechten und ' +
                '  M&ouml;glichkeiten im Rahmen der Datenschutzbestimmungen zu ' +
                '  erleichtern.<br /> ' +
                '  Es werden auch jene Ma&szlig;nahmen beschrieben, die wir ergriffen ' +
                '  haben, um die Sicherheit und Vertraulichkeit der Daten zu ' +
                '  sch&uuml;tzen.<br /> ' +
                '  Die Verarbeitung personenbezogener Daten wird von uns ' +
                '  ausschlie&szlig;lich f&uuml;r die Zwecke erhoben und verarbeitet, ' +
                '  die vor der Erhebung der Daten festgelegt wurden. Wir verarbeiten ' +
                '  nur diejenigen personenbezogenen Daten, die f&uuml;r die Abwicklung ' +
                '  unserer Leistungen und f&uuml;r die Verwaltung von KundInnen und ' +
                '  InteressentInnen ben&ouml;tigt werden. Dies nur solange, wie die ' +
                '  Leistung bezogen wird oder wir durch gesetzliche Bestimmungen dazu ' +
                '  verpflichtet sind.<br /> ' +
                '  Die Verarbeitung aller personenbezogenen Daten erfolgt unter ' +
                '  strikter Beachtung der geltenden datenschutzrechtlichen ' +
                '  Bestimmungen. Personenbezogene Daten werden von uns weder ' +
                '  ver&ouml;ffentlicht, noch unberechtigt an Dritte weitergegeben. Die ' +
                '  Datenverarbeitung erfolgt ausschlie&szlig;lich im EU-Inland.<br /><br /> ' +
                '  Diese Datenschutzerkl&auml;rung gilt f&uuml;r unsere Website ' +
                '  http://www.gewista.at. Einzelne Seiten k&ouml;nnen Links auf andere ' +
                '  AnbieterInnen innerhalb und au&szlig;erhalb der Gewista enthalten, ' +
                '  auf die sich die Datenschutzerkl&auml;rung nicht erstreckt. F&uuml;r ' +
                '  diese Inhalte &uuml;bernehmen wir keine Haftung. ' +
                '</p> ' +
                '<h5>Rechtliche Grundlagen unserer Datenverarbeitungen</h5> ' +
                '<p> ' +
                '  Wir verarbeiten Daten von KundInnen als auch von InteressentInnen ' +
                '  und InformationsbezieherInnen, die uns ihre personenbezogenen Daten, ' +
                '  aufgrund ihrer Anfrage zur Verf&uuml;gung gestellt haben.<br /><br /> ' +
                '  Die Datenverarbeitungen beruhen auf den folgenden ' +
                '  Rechtsgrundlagen:<br /><br /> ' +
                '  1. Die Verarbeitung der Daten von KundInnen ist f&uuml;r die ' +
                '  Erf&uuml;llung eines Vertrags, dessen Vertragspartei sie sind, oder ' +
                '  zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich, ' +
                '  die auf deren Anfrage erfolgen<br /> ' +
                '  2. Personenbezogenen Daten, die von den Betroffenen selbst ' +
                '  bereitgestellt wurden, wobei sie zu deren Verarbeitung f&uuml;r den ' +
                '  jeweiligen Zweck ausdr&uuml;cklich einwilligen. Diese Einwilligung ' +
                '  kann jederzeit widerrufen werden. ' +
                '</p> ' +
                '<h5>Auftragsverarbeiter</h5> ' +
                '<p> ' +
                '  Eine Auftragsdatenverarbeitung liegt vor, wenn ein ' +
                '  Auftragsverarbeiter mit der Verarbeitung personenbezogener Daten ' +
                '  beauftragt wird, ohne dass ihm die Verantwortung f&uuml;r den ' +
                '  zugeh&ouml;rigen Gesch&auml;ftsprozess &uuml;bertragen wird. In ' +
                '  diesen F&auml;llen schlie&szlig;en wir mit dem externen ' +
                '  Auftragsverarbeiter eine Vereinbarung &uuml;ber eine ' +
                '  Auftragsdatenverarbeitung ab. Dabei behalten wir die volle ' +
                '  Verantwortung f&uuml;r die datenschutzrechtlich korrekte ' +
                '  Durchf&uuml;hrung der Datenverarbeitung. Der Auftragsverarbeiter ' +
                '  darf personenbezogene Daten nur im Rahmen der Weisungen des ' +
                '  Verantwortlichen verarbeiten.<br /><br /> ' +
                '  Wir arbeiten nur mit Auftragsverarbeitern (wie z.B. Druckereien oder ' +
                '  Versandagenturen) zusammen, die hinreichend Garantien daf&uuml;r ' +
                '  bieten, dass geeignete technische und organisatorische ' +
                '  Ma&szlig;nahmen bei der Verarbeitung, im Einklang mit den ' +
                '  Anforderungen der DSGVO erfolgen und den Schutz der ' +
                '  personenbezogenen Daten gew&auml;hrleisten. Die Verarbeitung durch ' +
                '  einen Auftragsverarbeiter erfolgt nur auf Grundlage eines Vertrages ' +
                '  mit uns, welcher die Dauer, die Art und den Zweck der Verarbeitung ' +
                '  genau festlegt. Alle Auftragsverarbeiter, die personenbezogene Daten ' +
                '  verarbeiten und &uuml;berpr&uuml;fen, kontrollieren ' +
                '  regelm&auml;&szlig;ig, ob die datenschutzrechtlichen Bestimmungen ' +
                '  eingehalten werden. ' +
                '</p> ' +
                '<h5>Datenarten und Zweck der Verarbeitung</h5> ' +
                '<p> ' +
                '  Personenbezogene Daten unserer KundInnen und InteressentInnen werden ' +
                '  zu den Zwecken der Leistungs- und Informationsbereitstellung von uns ' +
                '  erhoben und verarbeitet. Die personenbezogenen Daten werden f&uuml;r ' +
                '  die Dauer ihres Leistungs- oder Informationsbezugs und solange noch ' +
                '  Anspr&uuml;che daraus bestehen k&ouml;nnen oder gesetzliche ' +
                '  Bestimmungen die Verarbeitung erfordern, gespeichert ' +
                '</p> ' +
                '<h5>Dauer der Datenspeicherung</h5> ' +
                '<p> ' +
                '  Wir speichern personenbezogene Daten nur solange, wie sie f&uuml;r ' +
                '  den Zweck der Verarbeitung ben&ouml;tigt werden und gesetzliche ' +
                '  Anspr&uuml;che bestehen k&ouml;nnen, bzw. solange es uns gesetzliche ' +
                '  Bestimmungen vorschreiben. Danach werden sie unwiderruflich ' +
                '  gel&ouml;scht.<br /><br /> ' +
                '  Zum Beispiel m&uuml;ssen aufgrund gesetzlicher Bestimmungen ' +
                '  (Aufbewahrungspflichten) des Unternehmensgesetzbuches (&sect; 212 ' +
                '  UGB) und der Bundesabgabenordnung (&sect; 132 BAO) f&uuml;r ' +
                '  Rechnungs- und Finanzdaten die betreffenden personenbezogenen Daten ' +
                '  f&uuml;r 7 Jahre gespeichert werden.<br /><br /> ' +
                '  Erhebung und Verarbeitung von personenbezogenen Daten bei Besuch ' +
                '  unserer Website<br /><br /> ' +
                '  Wenn Sie unsere Website besuchen, speichern unsere Webserver ' +
                '  tempor&auml;r jeden Zugriff in einer Protokolldatei. Folgenden Daten ' +
                '  werden dabei erfasst und f&uuml;r 6 Monate gespeichert: ' +
                '</p> ' +
                '<ul> ' +
                '  <li>IP-Adresse des anfragenden Rechners</li> ' +
                '  <li>Datum und Uhrzeit des Zugriffs</li> ' +
                '  <li>Name und URL der abgerufenen Daten</li> ' +
                '  <li>&Uuml;bertragene Datenmenge</li> ' +
                '  <li>Meldung, ob der Abruf erfolgreich war</li> ' +
                '  <li> ' +
                '    Erkennungsdaten des verwendeten Browser- und Betriebssystems ' +
                '  </li> ' +
                '</ul> ' +
                '<p> ' +
                '  Die Verarbeitung dieser Daten erfolgt zum Zweck, die Nutzung der ' +
                '  Website zu erm&ouml;glichen (Verbindungsaufbau), der ' +
                '  Systemsicherheit, der technischen Administration der ' +
                '  Netzinfrastruktur sowie zur Optimierung des Internetangebotes. Die ' +
                '  IP-Adresse wird nur bei Angriffen auf unsere Netzinfrastruktur ' +
                '  ausgewertet. ' +
                '</p> ' +
                '<h5>Cookies</h5> ' +
                '<p> ' +
                '  Unsere Website verwendet so genannte Cookies. Cookies richten auf ' +
                '  Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies ' +
                '  dienen dazu, unser Angebot nutzerfreundlicher, effektiver und ' +
                '  sicherer zu machen. Dabei handelt es sich um kleine Textdateien, die ' +
                '  mit Hilfe des Browsers auf Ihrem Endger&auml;t abgelegt werden.<br />Wir ' +
                '  nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. ' +
                '  Einige Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie ' +
                '  diese l&ouml;schen. Sie erm&ouml;glichen es uns, Ihren Browser beim ' +
                '  n&auml;chsten Besuch wiederzuerkennen.<br /> ' +
                '  Wenn Sie dies nicht w&uuml;nschen, so k&ouml;nnen Sie Ihren Browser ' +
                '  so einrichten, dass er Sie &uuml;ber das Setzen von Cookies ' +
                '  informiert und Sie dies nur im Einzelfall erlauben. Bei der ' +
                '  Deaktivierung von Cookies kann die Funktionalit&auml;t unserer ' +
                '  Website eingeschr&auml;nkt sein. ' +
                '</p> ' +
                '<h5>Web-Analyse - Google Analytics</h5> ' +
                '<p> ' +
                '  Diese Website benutzt Google Analytics, einen Webanalysedienst der ' +
                '  Google Inc. (&bdquo;Google&ldquo;). Google Analytics verwendet sog. ' +
                '  &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem Computer ' +
                '  gespeichert werden und die eine Analyse der Benutzung der Webseite ' +
                '  durch Sie erm&ouml;glichen. Die durch den Cookie erzeugten ' +
                '  Informationen &uuml;ber Ihre Benutzung der Webseite werden in der ' +
                '  Regel an einen Server von Google in den USA &uuml;bertragen und dort ' +
                '  gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser ' +
                '  &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google ' +
                '  zusammengef&uuml;hrt.<br /> ' +
                '  Im Auftrag des Betreibers dieser Website wird Google diese ' +
                '  Information benutzen, um Ihre Nutzung der Webseite auszuwerten, um ' +
                '  Reports &uuml;ber die Webseiten-Aktivit&auml;ten zusammenzustellen ' +
                '  und um weitere mit der Webseiten-Nutzung und der Internetnutzung ' +
                '  verbundene Dienstleistungen gegen&uuml;ber dem Webseitenbetreiber zu ' +
                '  erbringen. Sie k&ouml;nnen die Speicherung der Cookies durch eine ' +
                '  entsprechende Einstellung Ihrer Browser-Software verhindern; wir ' +
                '  weisen Sie jedoch darauf hin, dass Sie in diesem Fall ' +
                '  m&ouml;glicherweise nicht s&auml;mtliche Funktionen dieser Webseite ' +
                '  vollumf&auml;nglich werden nutzen k&ouml;nnen.<br /> ' +
                '  Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das ' +
                '  Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten ' +
                '  (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser ' +
                '  Daten durch Google verhindern, indem sie das unter dem folgenden ' +
                '  Link verf&uuml;gbare Browser-Plugin herunterladen und installieren: ' +
                '  http://tools.google.com/dlpage/gaoptout?hl=de. Alternativ zum ' +
                '  Browser-Add-On, insbesondere bei Browsern auf mobilen ' +
                '  Endger&auml;ten, k&ouml;nnen Sie die Erfassung durch Google ' +
                '  Analytics zudem verhindern, indem Sie auf diesen Link klicken. Es ' +
                '  wird ein Opt-Out-Cookie gesetzt, das die zuk&uuml;nftige Erfassung ' +
                '  Ihrer Daten beim Besuch dieser Website verhindert. Der ' +
                '  Opt-Out-Cookie gilt nur in diesem Browser und nur f&uuml;r unsere ' +
                '  Website und wird auf Ihrem Ger&auml;t abgelegt. L&ouml;schen Sie die ' +
                '  Cookies in diesem Browser, m&uuml;ssen Sie das Opt-Out-Cookie erneut ' +
                '  setzen. [Anm. Hinweise zur Einbindung des Opt-Out-Cookie finden Sie ' +
                '  unter: ' +
                '  <a ' +
                '    href="https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable" ' +
                '    target="_blank" ' +
                '    >https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable</a>' +
                '  ].<br /> ' +
                '  Wir nutzen Google Analytics weiterhin dazu, Daten aus ' +
                '  Double-Click-Cookies und auch AdWords zu statistischen Zwecken ' +
                '  auszuwerten. Sollten Sie dies nicht w&uuml;nschen, k&ouml;nnen Sie ' +
                '  dies &uuml;ber den Anzeigenvorgaben-Manager (<a ' +
                '    href="http://www.google.com/settings/ads/onweb/?hl=de" ' +
                '    target="_blank" ' +
                '    >http://www.google.com/settings/ads/onweb/?hl=de</a>) ' +
                '   deaktivieren. ' +
                '</p> ' +
                '<h5>Eyepin &ndash; newsletter</h5> ' +
                '<p> ' +
                '  Wir verwenden das Newsletter-tool von eyepin. Die Newsletter der ' +
                '  eyepin GmbH, Billrothstra&szlig;e 52, 1190 Wien, enthalten ' +
                '  sogenannte Z&auml;hlpixel. Ein Z&auml;hlpixel ist eine ' +
                '  Miniaturgrafik, die in solche E-Mails eingebettet wird, welche im ' +
                '  HTML-Format versendet werden, um eine Logdatei-Aufzeichnung und eine ' +
                '  Logdatei-Analyse zu erm&ouml;glichen. Dadurch kann eine statistische ' +
                '  Auswertung des Erfolges oder Misserfolges von ' +
                '  Online-Marketing-Kampagnen durchgef&uuml;hrt werden. Anhand des ' +
                '  eingebetteten Z&auml;hlpixels kann die eyepin GmbH erkennen, ob und ' +
                '  wann eine E-Mail von einer betroffenen Person ge&ouml;ffnet wurde. ' +
                '  Weiters wird &uuml;ber das Newsletter-Tracking erhoben, welche in ' +
                '  der E-Mail befindlichen Links von der betroffenen Person aufgerufen ' +
                '  wurden.<br /> ' +
                '  Solche &uuml;ber die in den Newslettern enthaltenen Z&auml;hlpixel ' +
                '  und das Newsletter-Tracking erhobenen personenbezogenen Daten, ' +
                '  werden von dem f&uuml;r die Verarbeitung Verantwortlichen ' +
                '  gespeichert und ausgewertet, um den Newsletterversand zu optimieren ' +
                '  und den Inhalt zuk&uuml;nftiger Newsletter noch besser den ' +
                '  Interessen der betroffenen Person anzupassen. Diese ' +
                '  personenbezogenen Daten werden nicht an Dritte weitergegeben. ' +
                '  Betroffene Personen sind jederzeit berechtigt, die ' +
                '  diesbez&uuml;gliche gesonderte, &uuml;ber das ' +
                '  Double-Opt-In-Verfahren abgegebene Einwilligungserkl&auml;rung ' +
                '  &uuml;ber die Abmeldung vom Erhalt des Newsletters zu widerrufen. ' +
                '  Nach einem Widerruf werden keinerlei weitere Daten erhoben und die ' +
                '  Adresse wird auf eine Sperrliste gesetzt, um die Abmeldung zu ' +
                '  dokumentieren und weitere Zusendungen zu verhindern.<br /> ' +
                '  Die Datenschutzerkl&auml;rung der eyepin GmbH finden Sie unter ' +
                '  <a ' +
                '    href="https://support.eyepin.com/hc/de/articles/360001151006-Datenschutz-bei-eyepin" ' +
                '    target="_blank" ' +
                '    >https://support.eyepin.com/hc/de/articles/360001151006-Datenschutz-bei-eyepin</a> ' +
                '</p> ' +
                '<h5>Datensicherheitsma&szlig;nahmen</h5> ' +
                '<p> ' +
                '  Die personenbezogenen Daten, die von uns verarbeitet werden, werden ' +
                '  mit besonderer Sorgfalt sowohl auf technischer als auch ' +
                '  organisatorischer Ebene aufbewahrt und gesichert. Sie sind vor ' +
                '  zuf&auml;lliger oder unrechtm&auml;&szlig;iger Zerst&ouml;rung und ' +
                '  vor Verlust gesch&uuml;tzt und wir stellen sicher, dass ihre ' +
                '  Verwendung ordnungsgem&auml;&szlig; erfolgt und dass die Daten ' +
                '  Unbefugten nicht zug&auml;nglich sind.<br /> ' +
                '  Alle unsere Auftragsverarbeiter sind aufgrund einer vertraglichen ' +
                '  Vereinbarung mit uns dazu verpflichtet, ebenfalls alle technischen ' +
                '  und organisatorischen Ma&szlig;nahmen f&uuml;r eine sichere ' +
                '  Verarbeitung zu ergreifen. Dies wird regelm&auml;&szlig;ig von einem ' +
                '  unserer Verantwortlichen &uuml;berpr&uuml;ft. ' +
                '</p> ' +
                '<h5>Datenschutzbeauftragter</h5> ' +
                '<p> ' +
                '  Der Datenschutzbeauftragte arbeitet eng mit der obersten ' +
                '  Organisationsebene zusammen und ist erster Ansprechpartner in Fragen ' +
                '  des Datenschutzes und der Datensicherheit. Sie finden sich in ' +
                '  regelm&auml;&szlig;igen Abst&auml;nden zusammen, um Fragen der ' +
                '  Datensicherheit und des Datenschutzes zu behandeln.<br /> ' +
                '  Er arbeitet im Bedarfsfall mit der Aufsichtsbeh&ouml;rde zusammen ' +
                '  und fungiert als Ansprechpartner f&uuml;r die Aufsichtsbeh&ouml;rde ' +
                '  in, mit der Verarbeitung personenbezogener Daten ' +
                '  zusammenh&auml;ngenden Fragen, einschlie&szlig;lich der vorherigen ' +
                '  Konsultation. Betroffene Personen k&ouml;nnen den ' +
                '  Datenschutzbeauftragten zu allen mit der Verarbeitung ihrer ' +
                '  personenbezogenen Daten und mit der Wahrnehmung ihrer Rechte im ' +
                '  Zusammenhang stehenden Fragen zu Rate ziehen.<br /> ' +
                '  Wir stellen sicher, dass der Datenschutzbeauftragte bei der ' +
                '  Erf&uuml;llung seiner Aufgaben keine Anweisungen erh&auml;lt. Die ' +
                '  Kontaktdaten unseres Datenschutzbeauftragten befinden sich in den ' +
                '  Kontaktinformationen am Ende der Datenschutzerkl&auml;rung. ' +
                '</p> ' +
                '<h5>Meldungsverpflichtung</h5> ' +
                '<p> ' +
                '  Im Fall einer Verletzung des Schutzes personenbezogener Daten sin ' +
                '  wir verpflichtet unverz&uuml;glich die Datenschutzbeh&ouml;rde von ' +
                '  dieser Verletzung zu benachrichtigen. Ist anzunehmen, dass durch ' +
                '  eine solche Verletzung Personen in ihrer Privatsph&auml;re oder die ' +
                '  personenbezogenen Daten selbst beeintr&auml;chtigt werden, kann die ' +
                '  Datenschutzbeh&ouml;rde - nach Ber&uuml;cksichtigung der ' +
                '  wahrscheinlichen nachteiligen Auswirkungen der Verletzung &ndash; ' +
                '  uns auffordern, eine Benachrichtigung der betroffenen Personen ' +
                '  durchzuf&uuml;hren. ' +
                '</p> ' +
                '<h5>Information &uuml;ber Betroffenenrechte</h5> ' +
                '<p> ' +
                '  Als Betroffene/r steht Ihnen ein Auskunftsrecht bez&uuml;glich der ' +
                '  &uuml;ber Sie gespeicherten personenbezogenen Daten, ein Recht auf ' +
                '  Richtigstellung unrichtiger Daten, auf Einschr&auml;nkung und ' +
                '  Widerspruch der Verarbeitung und auf L&ouml;schung zu. Um diese ' +
                '  Rechte aus&uuml;ben zu k&ouml;nnen, m&uuml;ssen Sie in geeigneter ' +
                '  Form ihre Identit&auml;t nachweisen.<br /> ' +
                '  Unsere Auskunft wird die verarbeiteten Daten, die Informationen ' +
                '  &uuml;ber deren Herkunft, allf&auml;llige Empf&auml;nger oder ' +
                '  Empf&auml;ngerkreise von &Uuml;bermittlungen, den Zweck der ' +
                '  Datenverwendung sowie die Rechtsgrundlagen hierf&uuml;r in allgemein ' +
                '  verst&auml;ndlicher Form anf&uuml;hren. Auf Ihr Verlangen sind auch ' +
                '  Namen und Adressen von Auftragsverarbeitern bekannt zu geben.<br /> ' +
                '  Als Auskunftswerber haben Sie am Auskunftsverfahren in einem Ihnen ' +
                '  zumutbaren Ausma&szlig; mitzuwirken, um ungerechtfertigten und ' +
                '  unverh&auml;ltnism&auml;&szlig;igen Aufwand beim Verantwortlichen ' +
                '  der Datenverarbeitung zu vermeiden.<br /> ' +
                '  Innerhalb von einem Monat nach Einlangen Ihres Begehrens werden wir ' +
                '  die Auskunft erteilen oder schriftlich begr&uuml;nden, warum sie ' +
                '  nicht oder nicht vollst&auml;ndig erteilt werden kann.<br /> ' +
                '  Zur Auskunft &uuml;ber Ihre personenbezogenen Daten beziehungsweise ' +
                '  deren Richtigstellung oder L&ouml;schung oder wenn Sie weitergehende ' +
                '  Fragen &uuml;ber die Verwendung Ihrer uns &uuml;berlassenen ' +
                '  personenbezogenen Daten haben, kontaktieren Sie bitte unseren ' +
                '  Datenschutzbeauftragten. Die Kontaktm&ouml;glichkeiten finden Sie am ' +
                '  Ende dieser Datenschutzerkl&auml;rung. ' +
                '</p> ' +
                '<h5>Die Datenschutzbeh&ouml;rde (DSB)</h5> ' +
                '<p> ' +
                '  Im Falle einer vermeintlichen Unzul&auml;nglichkeit des Schutzes ' +
                '  personenbezogener Daten besteht f&uuml;r Betroffene die ' +
                '  M&ouml;glichkeit Beschwerde bei der &ouml;sterreichischen ' +
                '  Datenschutzbeh&ouml;rde (<a ' +
                '    href="http://www.dsb.gv.at" ' +
                '    target="_blank" ' +
                '    >http://www.dsb.gv.at</a ' +
                '  >) zu erheben. ' +
                '</p> ' +
                '<h5>&Auml;nderung dieser Datenschutzerkl&auml;rung</h5> ' +
                '<p> ' +
                '  Da diese Hinweise der jeweils aktuellen Rechtslage unterliegen und ' +
                '  unsere Leistungen fortlaufend weiterentwickelt werden, behalten wir ' +
                '  uns vor, diese Datenschutzerkl&auml;rung k&uuml;nftig entsprechend ' +
                '  zu &auml;ndern. Wir empfehlen, diese Datenschutzerkl&auml;rung ' +
                '  regelm&auml;&szlig;ig zu lesen, um &uuml;ber den Schutz von uns ' +
                '  erfassten personenbezogenen Daten auf dem Laufenden zu bleiben.<br /><br /> ' +
                '  Der Verantwortliche ist die Gewista Werbegesellschaft mbH, ' +
                '  <br />Adresse: Litfa&szlig;stra&szlig;e 6, 1031 Wien, <br />E-Mail: ' +
                '  <a ' +
                '    href="mailto:gewista@gewista.at" ' +
                '    title="Mail an gewista@gewista.at" ' +
                '    >gewista@gewista.at</a ' +
                '  ><br /><br /> ' +
                '  Kontaktadresse des Datenschutzbeauftragten:<br /> ' +
                '  <a href="mailto:datenschutzbeauftragter@gewista.at" ' +
                '    >datenschutzbeauftragter@gewista.at</a ' +
                '  > ' +
                '</p>',
        },
    }
}

export default new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
});