import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";

Vue.use(Vuex);

const apiStatusUrl = window.location.origin + "/api/api/status";
//const apiStatusUrl = "https://previewbox.verlauf.at/api/api/status";
//const apiStatusUrl = Vue.prototype.$apiUrlStatus;

let initialState = {
  plakat: [],
  rollingboard: [],
  premiumboard: [],
  premiumscreen: [],
  citylight: [],
  digitalmedia: [],
  prefix: {
    digitalmedia: "",
    rollingboard: "",
    premiumboard: "",
    premiumscreen: "",
    plakat: "",
    citylight: ""
  },
  version: ""
};

export default new Vuex.Store({
  strict: false,
  state: {
    render: {
      plakat: [],
      rollingboard: [],
      premiumboard: [],
      premiumscreen: [],
      citylight: [],
      digitalmedia: [],
      prefix: {
        digitalmedia: "",
        rollingboard: "",
        premiumboard: "",
        premiumscreen: "",
        plakat: "",
        citylight: ""
      },
      version: ""
    }
  },
  getters: {
    getGlobalJobPrefix: state => payload => {
      return state.render.prefix[payload.type];
    },
    getJobsByType: state => type => {
      return state.render[type];
    },
    getFirstJobByType: state => type => {
      var t = state.render[type];
      var first = t[state.render[type].length - 1];
      if (first) {
        return first;
      } else {
        return {};
      }
    },
    getJobById: state => payload => {
      if (payload.id !== undefined && payload.id !== null) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        return state.render[payload.type][index];
      } else {
        return null;
      }
    },
    // get jobs by status, applies only for rlb and dm
    getJobsByStatus: state => status => {
      var t;
      var j;
      var stat = status;
      for (t in state.render) {
        if (!state.render.hasOwnProperty(t)) {
          continue;
        }
        if (t === "rollingboard" || t === "digitalmedia" || t === "premiumscreen") {
          for (j in state.render[t]) {
            if (!state.render[t].hasOwnProperty(j)) {
              continue;
            }
            if (
              state.render[t][j].uploadComplete === true &&
              state.render[t][j].status !== null &&
              state.render[t][j].status !== undefined &&
              state.render[t][j].status.progress !== undefined &&
              state.render[t][j].status.progress["percent"] !== "100" &&
              state.render[t][j].status.renderedAt === null //&&
              //state.render[t][j].status.jobSetting !== "none"
            ) {
              return state.render[t][j];
            }
          }
        }
      }

      return {};
    }
  },
  mutations: {
    resetStore(state) {
      //Object.keys(state.render).forEach(key => {
      //Object.assign(state.render, initialState[key]);
      //});

      state.render = Object.assign({}, state.render, initialState);
    },
    setJobSetting(state, payload) {
      if (payload.id !== undefined) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        Vue.set(state.render[payload.type][index], "setting", payload.setting);
      }
    },
    setStoreVersion(state, payload) {
      Vue.set(state.render, "version", payload.version);
    },
    setStatus(state, payload) {
      /*state.render[payload.type][payload.id] = Object.assign(
        {},
        state.render[payload.type][payload.id],
        payload.status
      );*/
      if (payload.id !== undefined) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        Vue.set(
          state.render[payload.type][index],
          "status",
          payload.status.status
        );
      }
    },
    setUploadComplete(state, payload) {
      var index = payload.id.replace(state.render.prefix[payload.type], "");
      Vue.set(state.render[payload.type][index], "uploadComplete", true);
    },
    setGlobalJobPrefix(state, payload) {
      var jid = Math.random()
        .toString(36)
        .substr(2);
      Vue.set(state.render.prefix, payload.type, jid);
    },
    setPreviewImage(state, payload) {
      var index = payload.id.replace(state.render.prefix[payload.type], "");

      if (payload.fileId) {
        Vue.set(
          state.render[payload.type][index].previewImage,
          payload.fileId,
          {}
        );
        state.render[payload.type][index].previewImage[
          payload.fileId
        ] = Object.assign(
          {},
          state.render[payload.type][index].previewImage[payload.fileId],
          payload.preview
        );
      }
      //Vue.set(state.render[payload.type][index].previewImage, payload.fileId);
    },
    setPreviewUploadComplete(state, payload) {
      var index = payload.id.replace(state.render.prefix[payload.type], "");
      Vue.set(
        state.render[payload.type][index].previewImage[payload.fileId],
        "finished",
        true
      );
    },
    createJob(state, payload) {
      var created = new Date().getTime() / 1000;
      /*state.render[payload.type][payload.id] = Object.assign(
        {},
        state.render[payload.type][payload.id],
        {
          jobId: payload.id,
          setting: payload.setting,
          createdAt: created,
          previewImage: {},
          poll: false
        }
      );*/
      Vue.set(state.render[payload.type], payload.index, {
        jobId: payload.id,
        setting: payload.setting,
        createdAt: created,
        previewImage: [],
        poll: false
        //status: {}
      });
    },
    startPoll(state, payload) {
      if (payload.id !== undefined) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        Vue.set(state.render[payload.type][index], "poll", true);
      }
    },
    stopPoll(state, payload) {
      if (payload.id !== undefined) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        Vue.set(state.render[payload.type][index], "poll", false);
      }
    },
    deleteJob(state, payload) {
      if (payload.id !== undefined) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        //Vue.set(state.render[payload.type][index], "poll", false);
        //Vue.delete(state.render[payload.type], index);
        Vue.set(state.render[payload.type], index, {});
      }
    },
    unsetJob(state, payload) {
      if (payload.id !== undefined) {
        var index = payload.id.replace(state.render.prefix[payload.type], "");
        //Vue.set(state.render[payload.type][index], "poll", false);
        //Vue.delete(state.render[payload.type], index);
        Vue.set(state.render[payload.type], index, {});
      }
    }
  },
  actions: {
    setStatus({ commit }, payload) {
      commit("setStatus", payload);
    },
    resetStore({ commit }) {
      commit("resetStore");
    },
    setJobSetting({ commit }, payload) {
      commit("setJobSetting", payload);
    },
    setStoreVersion({ commit }, payload) {
      commit("setStoreVersion", payload);
    },
    setUploadComplete({ commit }, payload) {
      commit("setUploadComplete", payload);
    },
    setPreviewUploadComplete({ commit }, payload) {
      commit("setPreviewUploadComplete", payload);
    },
    setGlobalJobPrefix({ commit }, payload) {
      commit("setGlobalJobPrefix", payload);
    },
    setPreviewImage({ commit }, payload) {
      //payload.preview = {};
      if (payload.fileId !== undefined) {
        payload.preview = {
          id: payload.fileId,
          image: payload.image,
          finished: payload.finished
        };
        commit("setPreviewImage", payload);
      }
    },
    deletePreviewImage({ commit }, payload) {
      payload.preview = {};
      payload.preview[payload.fileId] = {};
      commit("setPreviewImage", payload);
    },
    createJob({ commit }, payload) {
      commit("createJob", payload);
    },
    startPoll({ commit }, payload) {
      commit("startPoll", payload);
    },
    stopPoll({ commit }, payload) {
      commit("stopPoll", payload);
    },
    deleteJob({ commit }, payload) {
      commit("deleteJob", payload);
    },
    unsetJob({ commit }, payload) {
      commit("unsetJob", payload);
    },
    apiPoll({ commit }, payload) {
      //call api for status, but only if job is created
      var index = payload.id.replace(
        this.state.render.prefix[payload.type],
        ""
      );
      if (
        this.state.render[payload.type][index] !== undefined &&
        this.state.render[payload.type][index].poll !== undefined &&
        this.state.render[payload.type][index].poll !== false
      ) {
        axios
          .get(
            apiStatusUrl +
              "?jobId=" +
              payload.id +
              "&jobType=" +
              payload.type +
              "&jobSetting=" +
              payload.setting
          )
          .then(response => {
            if (response.data.data) {
              var statusData = {
                id: payload.id,
                type: payload.type,
                status: response.data.data
              };
              commit("setStatus", statusData);
            }
          })
          .catch(error => {
            //console.log(error);
            //if (error.response) {
            // console.log(error.response);
            //}
          });
      }
    }
  },
  plugins: [createPersistedState()]
});
