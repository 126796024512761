<template>
  <header>
    <div class="wrapper col-12">
      <div class="row">
        <div class="col-12 header-top">
          <div class="col-12 col-md-8 col-lg-4 mx-auto text-center">
            <router-link class="site-title" @click="setActive" to="/"
              >{{ $t('base.previewbox') }}</router-link
            >
          </div>
          <div class="progress-status" @click="goToJobType" v-if="statusshow">
            <span class="progress-status-label">Status {{ statustype }}</span>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="statuspercent"
                aria-valuemin="0"
                aria-valuemax="100"
                :style="statusStyle"
              >
                <span class="sr-only">{{ statuspercent }}% Complete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="!hasWebKey">
        <div class="col-12 header-bottom">
          <div class="col-12 col-lg-8 mx-auto text-center">
            <nav class="navbar navbar-expand-md navbar-light fixed-top">
              <button
                class="navbar-toggler"
                v-bind:class="collapsed"
                type="button"
                data-toggle="collapse"
                @click="toggleMobileMenu"
                data-target="#navbarMainMenu"
                aria-controls="navbarMainMenu"
                :aria-expanded="showMenu"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>

              <div
                class="collapse navbar-collapse"
                v-bind:class="{ show: showMenu }"
                id="navbarMainMenu"
              >
                <ul class="navbar-nav mx-auto">
                  <li
                    class="nav-item"
                    v-bind:class="{ active: linkActive.plakat }"
                    v-on:click="setActive"
                  >
                    <router-link
                      class="nav-link"
                      @click="setActive"
                      to="/plakat"
                      >{{ $t('menu.plakat') }}</router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-bind:class="{ active: linkActive.citylight }"
                    v-on:click="setActive"
                  >
                    <router-link
                      class="nav-link"
                      @click="setActive"
                      to="/citylight"
                      >{{ $t('menu.citylight') }}</router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-bind:class="{ active: linkActive.premiumboard }"
                    v-on:click="setActive"
                  >
                    <router-link
                      class="nav-link"
                      @click="setActive"
                      to="/premiumboard"
                      >{{ $t('menu.premiumboard') }}</router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-bind:class="{ active: linkActive.digitalescitylight }"
                    v-on:click="setActive"
                  >
                    <router-link
                      class="nav-link"
                      @click="setActive"
                      to="/digitalescitylight"
                      >{{ $t('menu.digitalescitylight') }}</router-link
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-bind:class="{ active: linkActive.premiumscreen }"
                    v-on:click="setActive"
                  >
                    <router-link
                      class="nav-link"
                      @click="setActive"
                      to="/premiumscreen"
                      >{{ $t('menu.premiumscreen') }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "ItrHeader",
  data: function() {
    return {
      linkActive: {
        plakat: false,
        premiumboard: false,
        citylight: false,
        digitalescitylight: false,
        premiumscreen: false,
        home: false
      },
      showMenu: false
    };
  },
  props: {
    statuspercent: Number,
    statustype: String,
    statusshow: Boolean
  },
  methods: {
    setActive(event, type = null) {
      var path;
      if (type) {
        path = type;
      } else {
        path = event.target.pathname;
        path = path.replace("/", "");
      }
      this.linkActive.plakat = false;
      this.linkActive.premiumboard = false;
      this.linkActive.premiumscreen = false;
      this.linkActive.citylight = false;
      this.linkActive.digitalescitylight = false;
      this.linkActive.home = false;
      if (path !== "") {
        this.linkActive[path] = true;
      } else {
        this.linkActive.home = true;
      }

      this.toggleMobileMenu();
    },
    goToJobType() {
      this.$router.push("/" + this.statustype);
      this.setActive(null, this.statustype);
    },
    toggleMobileMenu() {
      if (this.showMenu === true) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    }
  },
  computed: {
    statusStyle() {
      return "width:" + this.statuspercent + "%";
    },
    collapsed() {
      if (this.showMenu === false) {
        return "collapsed";
      } else {
        return "";
      }
    },
    hasWebKey() {
      return this.$apiAccessDomains.some(
        domain => domain.key === this.$route.query.key
      );
    }
  }
};
</script>

<style scoped lang="scss">
/******** Header *******************************************************************************/
/***********************************************************************************************/
@import "../assets/css/settings.scss";

header a {
  color: $color_header_link;
}
.header-top {
  background-color: $color_header_top;
  text-transform: uppercase;
  padding: 1.5rem 0 1rem 0;
  position: relative;
  min-height: 30px;
  a.site-title {
    font-size: $subtitle-size;
    font-weight: $font-weight-black;
    line-height: 1 !important;
    &:active,
    &:hover,
    &:focus {
      color: $color_link;
      text-decoration: none;
    }
  }
  .progress-status {
    position: absolute;
    right: 1.5rem;
    top: 0.8rem;
    min-width: 160px;
    cursor: pointer;
    .progress-status-label {
      font-size: $small-size;
      color: $color_link !important;
    }
  }
}
@media screen and (max-width: $break-md) {
  .header-top .progress-status {
    display: none;
  }
}
.header-bottom {
  background-color: $color_header_bottom;
  text-transform: uppercase;
  min-height: 35px !important;
  padding: 0;
  * {
    box-sizing: border-box;
  }
  div {
    padding: 0 !important;
  }
  .navbar {
    padding: 0 !important;
    position: absolute;
    .navbar-nav {
      margin-bottom: 0.2rem !important;
    }
    .navbar-toggler {
      border: unset;
      margin-left: auto;
      margin-right: auto;
      outline: none;
      padding-bottom: 0;
      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        height: 30px;
      }
    }
    .navbar-collapse {
      background-color: $color_header_bottom_transparent;
    }
  }
  ul {
    margin-bottom: 0.4rem;
  }
  li {
    display: inline-block;
    list-style: outside none none;
    margin: 0 1em;
    padding: 0;
  }
  a.nav-link {
    padding: 0.5em 0 0.2em 0;
    color: $color_header_link !important;
    position: relative;
    letter-spacing: 1px;
    text-decoration: none;
    &::before,
    &::after {
      position: absolute;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    &::before {
      top: 0;
      display: block;
      height: 2px;
      width: 0;
      content: "";
      background-color: $color_background;
    }
    &::after {
      left: 0;
      top: 0;
      padding: 0.5em 0;
      position: absolute;
      content: attr(data-hover);
      color: $color_header_link;
      white-space: nowrap;
      max-width: 0;
      overflow: hidden;
    }
    &:hover {
      &::before {
        opacity: 1;
        width: 100%;
      }
      &::after {
        max-width: 100%;
      }
    }
  }
  li.active {
    a {
      &::after {
        max-width: 100%;
      }
      &::before {
        opacity: 1;
        width: 100%;
      }
    }
  }
  li {
    a.router-link-active {
      &::after {
        max-width: 100%;
      }
      &::before {
        opacity: 1;
        width: 100%;
      }
    }
  }
}

@media (max-width: $break-md) {
  .header-bottom {
    a.nav-link {
      &:hover {
        &::before {
          opacity: 1;
          width: 0;
        }
        &::after {
          max-width: 100%;
        }
      }
    }
    li.active {
      a {
        &::after {
          max-width: 100%;
        }
        &::before {
          opacity: 1;
          width: 0;
        }
      }
    }
    li {
      a.router-link-active {
        &::after {
          max-width: 100%;
        }
        &::before {
          opacity: 1;
          width: 0;
        }
      }
    }
    .navbar {
      .navbar-toggler {
        border: unset;
        margin-left: auto;
        margin-right: auto;
        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
      }
      .navbar-toggler[aria-expanded="true"] {
        .navbar-toggler-icon {
          background-image: none;
          &:before {
            font-family: "itr-icon" !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f00d";
            font-size: 1.5rem;
            color: white;
          }
        }
      }
      .navbar-collapse {
        background-color: $color_header_bottom_transparent;
        .navbar-nav {
          margin-left: 10px !important;
          margin-right: 10px !important;
          padding-bottom: 0.7rem;
          padding-top: 0.7rem;
          border-top: 1px solid white;
        }
      }
    }
  }
}
</style>
