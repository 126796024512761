<template>
  <div class="container-fluid">
    <div id="app">
      <itr-header
        :statusshow="statusShow"
        :statustype="jobType"
        :statuspercent="jobPercent"
      ></itr-header>
      <main>
        <!--<transition name="fade" mode="out-in">-->
        <router-view />
        <!--</transition>-->
      </main>
      <itr-footer></itr-footer>
      <vue-snotify></vue-snotify>
    </div>
  </div>
</template>

<script>
import ItrHeader from "@/components/ItrHeader.vue";
import ItrFooter from "@/components/ItrFooter.vue";

export default {
  name: "app",
  data: function() {
    return {
      version: "2.0"
    };
  },
  components: {
    ItrHeader,
    ItrFooter
  },
  methods: {
    apiPoll() {
      for (var t in this.plakat) {
        if (
          !this.plakat.hasOwnProperty(t) ||
          this.plakat[t].jobId === undefined
        )
          continue;
        this.store.dispatch("apiPoll", {
          type: "plakat",
          id: this.plakat[t].jobId,
          setting: this.plakat[t].setting
        });
      }

      for (var u in this.rollingboard) {
        if (
          !this.rollingboard.hasOwnProperty(u) ||
          this.rollingboard[u].jobId === undefined
        )
          continue;
        this.store.dispatch("apiPoll", {
          type: "rollingboard",
          id: this.rollingboard[u].jobId,
          setting: this.rollingboard[u].setting
        });
      }

      for (var v in this.citylight) {
        if (
          !this.citylight.hasOwnProperty(v) ||
          this.citylight[v].jobId === undefined
        )
          continue;
        this.store.dispatch("apiPoll", {
          type: "citylight",
          id: this.citylight[v].jobId,
          setting: this.citylight[v].setting
        });
      }

      for (var v in this.premiumboard) {
        if (
          !this.premiumboard.hasOwnProperty(v) ||
          this.premiumboard[v].jobId === undefined
        )
          continue;
        this.store.dispatch("apiPoll", {
          type: "premiumboard",
          id: this.premiumboard[v].jobId,
          setting: this.premiumboard[v].setting
        });
      }

      for (var w in this.digitalmedia) {
        if (
          !this.digitalmedia.hasOwnProperty(w) ||
          this.digitalmedia[w].jobId === undefined
        )
          continue;
        this.store.dispatch("apiPoll", {
          type: "digitalmedia",
          id: this.digitalmedia[w].jobId,
          setting: this.digitalmedia[w].setting
        });
      }

      for (var u in this.premiumscreen) {
        if (
            !this.premiumscreen.hasOwnProperty(u) ||
            this.premiumscreen[u].jobId === undefined
        )
          continue;
        this.store.dispatch("apiPoll", {
          type: "premiumscreen",
          id: this.premiumscreen[u].jobId,
          setting: this.premiumscreen[u].setting
        });
      }
    },
    checkJobLifetime() {
      // Delete outdated jobs from state machine
      //var seconds = new Date().getTime() / 1000;
      var seconds = Date.now() / 1000;
      var timer = 60 * 60 * 24;

      for (var t in this.plakat) {
        if (!this.plakat.hasOwnProperty(t)) continue;
        if (seconds - this.plakat[t].createdAt > timer) {
          this.store.dispatch("deleteJob", {
            type: "plakat",
            id: t
          });
        }
      }

      for (var u in this.rollingboard) {
        if (!this.rollingboard.hasOwnProperty(u)) continue;
        if (seconds - this.rollingboard[u].createdAt > timer) {
          this.store.dispatch("deleteJob", {
            type: "rollingboard",
            id: u
          });
        }
      }

      for (var v in this.citylight) {
        if (!this.citylight.hasOwnProperty(v)) continue;
        if (seconds - this.citylight[v].createdAt > timer) {
          this.store.dispatch("deleteJob", {
            type: "citylight",
            id: v
          });
        }
      }

      for (var v in this.premiumboard) {
        if (!this.premiumboard.hasOwnProperty(v)) continue;
        if (seconds - this.premiumboard[v].createdAt > timer) {
          this.store.dispatch("deleteJob", {
            type: "premiumboard",
            id: v
          });
        }
      }

      for (var w in this.digitalmedia) {
        if (!this.digitalmedia.hasOwnProperty(w)) continue;
        if (seconds - this.digitalmedia[w].createdAt > timer) {
          this.store.dispatch("deleteJob", {
            type: "digitalmedia",
            id: w
          });
        }
      }

      for (var u in this.premiumscreen) {
        if (!this.premiumscreen.hasOwnProperty(u)) continue;
        if (seconds - this.premiumscreen[u].createdAt > timer) {
          this.store.dispatch("deleteJob", {
            type: "premiumscreen",
            id: u
          });
        }
      }
    },
    checkGlobalJobPrefix() {
      var prefix = this.$store.state.render.prefix.premiumscreen;
      if (prefix === "" || prefix === undefined) {
        this.$store.dispatch("setGlobalJobPrefix", {
          type: "premiumscreen"
        });
      }

      prefix = this.$store.state.render.prefix.digitalmedia;
      if (prefix === "" || prefix === undefined) {
        this.$store.dispatch("setGlobalJobPrefix", {
          type: "digitalmedia"
        });
      }

      prefix = this.$store.state.render.prefix.citylight;
      if (prefix === "" || prefix === undefined) {
        this.$store.dispatch("setGlobalJobPrefix", {
          type: "citylight"
        });
      }

      prefix = this.$store.state.render.prefix.premiumboard;
      if (prefix === "" || prefix === undefined) {
        this.$store.dispatch("setGlobalJobPrefix", {
          type: "premiumboard"
        });
      }

      prefix = this.$store.state.render.prefix.rollingboard;
      if (prefix === "" || prefix === undefined) {
        this.$store.dispatch("setGlobalJobPrefix", {
          type: "rollingboard"
        });
      }

      prefix = this.$store.state.render.prefix.plakat;
      if (prefix === "" || prefix === undefined) {
        this.$store.dispatch("setGlobalJobPrefix", {
          type: "plakat"
        });
      }
    },
    checkStoreVersion() {
      if (this.version !== this.$store.state.render.version) {
        return false;
      } else {
        return true;
      }
    },
    recreateStore() {
      //console.log("recreate Store");
      this.$store.dispatch("resetStore");
      this.$store.dispatch("setStoreVersion", {
        version: this.version
      });
    }
  },
  mounted: function() {
    if (!this.checkStoreVersion()) {
      this.recreateStore();
    }

    this.checkGlobalJobPrefix();

    this.checkJobLifetime();
    this.checkJobPoll();

    var self = this;
    this.interval = setInterval(function() {
      self.apiPoll();
    }, 1000);
  },
  computed: {
    store() {
      return this.$store;
    },
    jobState() {
      return this.$store.getters.getJobsByStatus("hello");
    },
    jobPercent() {
      if (this.jobState.status !== undefined) {
        return this.jobState.status.progress["percent"];
      }
      return 0;
    },
    jobType() {
      if (this.jobState.status !== undefined) {
        if (this.jobState.status.jobType === "digitalmedia") {
          return "Digitales City Light";
        }
        if (this.jobState.status.jobType === "rollingboard") {
          return "Premium Board";
        }
        if (this.jobState.status.jobType === "premiumscreen") {
          return "Premium Screen";
        }
        return this.jobState.status.jobType;
      }
    },
    statusShow() {
      if (this.jobState.status !== undefined && this.jobPercent !== 100) {
        return true;
      } else {
        return false;
      }
    }
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss">
@import "assets/css/settings.scss";
@import "assets/css/fonts.css";

/******** Setup ********************************************************************************/
/***********************************************************************************************/

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}
body {
  font-family: $font-default !important;
  font-weight: $font-weight-default !important;
  font-size: $default-size !important;
  line-height: $line-height !important;
  color: $color_font !important;
  height: 100% !important;
  background-color: $color_background !important;
}
@media all and (min-width: $break-md) {
  body {
    min-height: 100%;
    height: auto !important;
    position: relative;
    /*padding-bottom: 390px;*/
  }
}
@media all and (min-width: $break-lg) {
  body {
    background-size: 1px 500px;
  }
}
img {
  display: block;
  border: 0 none;
  height: auto;
  width: 100%;
}
button:focus,
button:active,
button:hover,
a:focus,
a:active,
a:hover {
  outline: 0;
}
a,
a:hover,
a:focus,
a:active {
  color: $color_link !important;
}
table {
  *border-collapse: collapse;
  border-spacing: 0;
}
h1 {
  font-size: $h1-size;
  text-transform: uppercase;
}
h2 {
  font-size: $h2-size;
  text-transform: uppercase;
  &.subtitle {
    font-size: $subtitle-size;
    font-weight: $font-weight-bold;
  }
}
h3 {
  font-size: $h3-size !important;
  text-transform: uppercase;
  font-weight: $font-weight-bold !important;
}
h4 {
  font-size: $h4-size;
}
h5 {
  font-size: $h5-size;
  font-weight: $font-weight-bold;
}
.nocaps {
  text-transform: none !important;
}
.btn {
  border-radius: $border-radius-small;
  padding: 0.215rem 2rem;
  text-transform: uppercase;
  font-size: $small-size;
  &.btn-filled,
  &.btn-filled:hover {
    background-color: $color_main_light;
    color: $color_link;
    outline: none;
  }
  &.btn-outline-light:hover {
    background-color: $color_main_light !important;
  }
  &:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}
.margin-bottom {
  margin-bottom: $mb2;
}
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 15px;
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 30px !important;
    padding-left: 30px !important;
    padding-top: 30px !important;
  }
}

.content-wrapper {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

ul {
  margin-left: 1rem;
}

/******** Content *******************************************************************************/
/***********************************************************************************************/

main {
  background-color: $color_main_default;
  .bg-darkgrey {
    background-color: $color_main_dark !important;
  }
  .bg-light {
    background-color: $color_main_light !important;
  }
}
.title {
  padding-top: $mt2;
  h1 {
    font-size: $title-size;
    font-weight: $font-weight-black;
  }
  &.pad {
    padding-bottom: $mt2;
  }
}
@media screen and (max-width: $break-sm) {
  .title {
    h1 {
      font-size: $h1-size;
    }
  }
}
h2.line {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
  font-size: $h3-size;
  font-weight: $font-weight-black;
  margin-top: 2rem;
  margin-bottom: 2rem;
  &::before {
    margin-right: 10px;
  }
  &::after {
    margin-left: 10px;
  }
  &::before,
  &::after {
    display: block;
    flex-grow: 1;
    height: 1px;
    content: "\a0";
    background-color: $color_h2_line;
    position: relative;
    top: 0.6em;
  }
}
hr.line {
  margin-top: 2rem;
  margin-bottom: 2rem;
  flex-grow: 1;
  height: 1px;
  background-color: $color_h2_line;
  border: 0;
}
.introtext {
  text-align: left;
  padding: 1.5rem;
  font-size: $large-size;
}
a.close-icon {
  text-decoration: none;
  color: $color_main_light;
  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: $color_link;
  }
}

.bg-white {
  color: $color_main_black;
}

.accordion {
  border-radius: $border-radius-default;
  margin: 1.8rem;
  margin-top: 1.5rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.intro-content-header.m-3 {
  margin-bottom: 0.3rem !important;
}

/******** Upload-Form **************************************************************************/
/***********************************************************************************************/

.upload-forms {
  //margin: 35px 0;
}
.upload-form {
  padding: $padding-boxes-default;

  .upload-form-title {
    color: $color_main_light;
    text-align: center;
    h3 {
      font-weight: $font-weight-black !important;
    }
  }
  .upload-form-box {
    min-height: 220px;
    height: 220px;
    border-radius: $border-radius-default;
    background-color: $color_main_light;
    text-align: center;
    position: relative;
    padding: $padding-boxes-default;
    display: table;
    width: 100%;
    &:not(.uploading) .dropzone-custom-content::after {
      content: "";
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      border: 1px dashed $color_header_link;
    }
    &.uploading .img-uploadform,
    &.uploading label {
      display: none;
    }
    .dz-clickable {
      min-height: 190px;
    }
    form {
      display: table-cell;
      vertical-align: middle;
    }
    .upload-box-file {
      display: block;
    }
    .dropzone-custom-content label {
      margin-top: 0.8rem;
    }
  }
}
.progress-bars {
  &.lesspad {
    padding: 1.5rem 0;
  }
}
.progress-status-label {
  color: $color_main_light;
}
.progress {
  background-color: $color_main_dark !important;
  height: 0.5rem !important;
  &.margin-top {
    margin-top: 0.5rem;
  }
  &.large {
    height: 1rem !important;
    border-radius: 0.5rem;
  }
  .progress-title-inline {
    text-transform: uppercase;
    color: $color_font;
    mix-blend-mode: difference;
    padding-left: 10px;
  }
}
.progress-bar {
  background-color: $color_header_link !important;
  border-radius: 1rem;
}
img.img-uploadform {
  max-width: calc(100% - 4rem);
  max-height: 120px;
  width: auto;
  padding-top: 1.3rem;
}
img.img-preview {
  max-width: 20%;
  padding-top: 1.3rem;
}
.radio-boxes {
  margin-top: $mt1;
  padding-bottom: $mb1;
  & > .row > .row {
    width: 100%;
  }
  .radio-img {
    margin: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem;
    .radio-box {
      background-color: $color_main_medium;
      border-radius: $border-radius-default;
      padding: $padding-boxes-default;
      padding-bottom: 0.7rem;
      min-height: 200px;
      min-width: 200px;
      &.checked {
        background-color: $color_main_dark;
        .custom-control-label {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .custom-radio {
      margin-top: 0.7rem;
      margin-bottom: 0.3rem;
    }
    .custom-control-label {
      padding-top: 0.4rem;
      padding-left: 0.3rem;
      color: rgba(255, 255, 255, 0.5);
      font-size: $small-size;
      &::before {
        border: 1px solid $color_main_light;
        background-color: transparent;
        width: 1.4rem;
        height: 1.4rem;
      }
      &::after {
        width: 1.5rem;
        height: 1.5rem;
        top: 0.15rem;
        left: -1.5rem;
        background-image: none !important;
      }
    }
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
      background-image: none;
      font-family: "itr-icon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 1.6rem;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f058";
    }

    .custom-radio
      .custom-control-input:checked
      ~ .custom-control-label::before {
      background-color: $color_main_dark;
      border: 0 !important;
      box-shadow: none !important;
    }
  }
  .radio-navigation {
    .navigation-next,
    .navigation-prev {
      a {
        text-decoration: none;
      }
      .icon {
        line-height: 2;
        font-size: 1.6rem;
      }
      &.disabled {
        opacity: 0.5;
      }
    }
    .navigation-next {
      text-align: left;
      padding-left: 0.3rem;
    }
    .navigation-prev {
      text-align: right;
      padding-right: 0.3rem;
    }
  }
}

.submit {
  padding-top: 1.3rem;
  padding-bottom: 3rem;
  .btn {
    width: auto;
    font-weight: bold;
    padding: 0.4rem 0.8rem 0.4rem 0.8rem;
    font-size: $default-size;
    outline: none;
    border-radius: $border-radius-default;
  }
  &.abort {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

/******** Results ******************************************************************************/
/***********************************************************************************************/

.render-results {
  &:not(.results-rlb) {
    padding-bottom: 2rem;
  }
}
.result .result-box {
  background-color: $color_main_dark;
  padding: $padding-boxes-default $padding-boxes-add;
  margin: 0.5rem;
  border-radius: $border-radius-default;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-height: 70px;
  .result-title {
    color: $color_main_light;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    h3 {
      font-size: $h6-size !important;
      line-height: 1.7;
    }
  }
  .result-close {
    position: absolute;
    right: 24px;
    top: 9px;
    color: $color_main_light;
    cursor: pointer;
    font-size: 1.5rem;
    a {
      color: $color_main_light;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: $color_main_black;
      }
    }
  }
  .result-image {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
  .result-count {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: $color_main_dark;
    position: relative;
    border: 2px solid $color_main_light;
    width: 40px;
    margin-top: 0.9rem;
    font-weight: $font-weight-bold;
    &.count-multiple::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-bottom: 2px solid $color_main_light;
      border-right: 2px solid $color_main_light;
      left: 6px;
      top: 6px;
    }
    a,
    a:active,
    a:hover,
    a:focus {
      color: $color_main_light !important;
      text-decoration: none !important;
    }
  }
  .result-link {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: $color_main_dark;
    position: relative;
    width: 40px;
    margin-top: 0.9rem;
    font-weight: $font-weight-bold;
    a,
    a:active,
    a:hover,
    a:focus {
      color: $color_main_light !important;
      text-decoration: none !important;
    }
  }
  .result-upload-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: $color_main_dark;
    position: relative;
    width: 100%;
    margin-top: 0.9rem;
    font-weight: $font-weight-bold;
  }
  .result-loading {
    min-height: 213px;
    padding-top: 4rem;
    .progress-bars {
      &.lesspad {
        padding: 1.5rem 0;
      }
    }
    .progress-status {
      width: 100%;
    }
    .progress {
      background-color: $color_main_light !important;
      height: 0.5rem !important;
      &.margin-top {
        margin-top: 0.5rem;
      }
      &.large {
        height: 1rem !important;
        border-radius: 0.5rem;
      }
      .progress-title-inline {
        text-transform: uppercase;
        color: $color_main_default;
      }
    }
    .progress-bar {
      background-color: $color_link !important;
      border-radius: 1rem;
    }
  }
}

.results-rlb {
  .img-fluid {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .result-count,
  .result-title {
    display: none;
  }
}
/******** Image Gallery*************************************************************************/
/***********************************************************************************************/

.img-gallery {
  padding-top: $mt1;
  h3 {
    color: $color_main_light;
  }
}
.img-gallery-images {
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
}
.img-gallery-navigation {
  &.navigation-next,
  &.navigation-prev {
    border-radius: 25px;
    height: 40px;
    width: 40px;
    background-color: $color_main_dark;
    position: absolute;
    a {
      text-decoration: none;
    }
    .icon {
      line-height: 1.3;
      font-size: 1.9rem;
    }
  }
  &.navigation-next {
    right: 0;
    top: calc(50% - 40px / 2);
  }
  &.navigation-prev {
    left: 0;
    top: calc(50% - 40px / 2);
  }
}
/******** Navigation ***************************************************************************/
/***********************************************************************************************/

.navigation {
  padding: $mt1 0;
}
/******** Downloads ****************************************************************************/
/***********************************************************************************************/

.itr-download {
  h3 {
    color: $color_main_light;
  }
}

.downloads {
  margin-top: $mt1;
  margin-bottom: $mb1;
  .download {
    text-transform: uppercase;
    font-size: $extra-small-size;
    line-height: $line-height-small;
    i.icon {
      display: block;
      font-size: 1.6rem;
      padding-bottom: 0.2rem;
    }
    a,
    a:hover,
    a:focus,
    a:visited {
      text-decoration: none !important;
    }
  }
}

@media (max-width: $break-md) {
  .container {
    width: 100%;
    max-width: 100% !important;
  }
}

/******** Toastr modifications ******************************************************************/
/***********************************************************************************************/

.toast-close-button {
  opacity: 0.9 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90) !important;
  filter: alpha(opacity=90) !important;
}
.toast-container > div {
  opacity: 0.9 !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90) !important;
  filter: alpha(opacity=90) !important;
}
.snotifyToast__title {
  font-size: $h4-size !important;
  line-height: 1.1em;
  margin-bottom: 5px;
  color: #fff;
}
.snotifyToast__body {
  font-size: $default-size !important;
}

/******** Video JS *****************************************************************************/
/***********************************************************************************************/

.vjs-poster,
.vjs-error-display,
.vjs-modal-dialog,
.vjs-control-bar,
.vjs-loading-spinner,
.vjs-big-play-button,
.vjs-resize-manager {
  display: none;
}

/******** Snotify *****************************************************************************/
/***********************************************************************************************/

.snotifyToast__inner {
  background-color: $color_header_bottom;
}
</style>
